import gql from "graphql-tag";

export const AddNewFilter = gql `mutation addNewFilter($_id: ID!, $filterId: ID!) {
  addNewFilter(_id: $_id, filterId: $filterId) {
    _id
    name
    path
    status
  }
}`


export const RemoveFilterOnLab = gql `mutation removeFilterOnLab($filterId: ID!, $labId: ID!) {
  removeFilterOnLab(filterId: $filterId, labId: $labId) {
    _id
    name
    status
  }
}`