<template>
	<div class="container-fluid p-4">
		<div class="row">
			<!-- Page Header -->
			<div class="col-lg-12 col-md-12 col-12">
				<div
					class="
						border-bottom
						pb-4
						mb-4
						d-md-flex
						align-items-center
						justify-content-between
					"
				>
					<div class="mb-3 mb-md-0">
						<h1 class="mb-1 h2 fw-bold">
							Modifier le Type de filtre
							<!-- <span class="fs-5">(210 Courses)</span> -->
						</h1>
					</div>
					<div>
						<a
							class="btn btn-secondary"
							@click="$router.push({ name: 'Types' })"
						>
							Retour
						</a>
					</div>
				</div>
			</div>
		</div>
		<div class="row justify-content-md-center">
			<!-- {{labDetailsInput}} -->
			<div class="col-lg-8 col-md-8 col-12">
				<!-- Card -->
				<div class="card mb-4">
					<!-- Card Header -->
					<div class="modal-body">
						<form>

							<div class="mb-3">
								<label class="form-label" for="title">
									Nom du type de filtre <span class="text-danger">*</span>
								</label>
								<input
                                    v-model="form.input.title"
									type="text"
									class="form-control"
									placeholder="Nom du type de filtre"
									required
								/>
							</div>

							<div class="mb-3">
								<label class="form-label" for="title">
									Description du type de filtre <span class="text-danger">*</span>
								</label>
								<input
                                    v-model="form.input.description"
									type="text"
									class="form-control"
									placeholder="Description du type de filtre"
									required
								/>
							</div>

                            <div class="mb-4">
								<label class="form-label" for="title">
									Status <span class="text-danger">*</span>
								</label>
								<select
                                    disabled=""
                                    v-model="form.input.isActive"
									class="select-style" data-width="100%" required
								>
									<option value=true>Activé</option>
									<option value=false>Désactivé</option>
								</select>
								<label for="customSwitch1" style="margin-right: 5px;"> Status du filtre </label>
                                <input v-model="form.input.isActive" type="checkbox" id="customSwitch1">
							</div>

							<div
								class="flex"
								style="display: flex; justify-content: end"
							>
								
								<button
                                    @click.prevent="updateType()"
									type="submit"
									class="btn btn-primary"
									style="margin-right: 5px"
								>
									Enregistrer
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { FindFilter } from "../../gql/queries/typefilters"
import { UpdateTypeFilter } from "../../gql/mutations/createTypeFilter"

export default { 
    data() {
        return {
            FindFilter,
            typefilterID: this.$route.params.id,
            form: {
                input: {
                    title: null,
                    description: null,
                    isActive: null
                },
            },
            UpdateTypeFilter

        }
    },

    methods: {
        async updateType() {
            // const response2 = 
            await this.$apollo.mutate({
				mutation: UpdateTypeFilter,
				variables: {
                    _id: this.typefilterID,
                    updateTypeFilterInput: {
                        title: this.form.input.title,
                        description: this.form.input.description,
                        isActive: this.form.input.isActive
                    }
				},
			})
            console.log(this.$apollo.mutate);
            this.$swal({
				toast: true,
				position: 'top-end',
				showConfirmButton: false,
				timer: 1200,
				timerProgressBar: true,
				didOpen: (toast) => {
					toast.addEventListener('mouseenter', this.$swal.stopTimer)
					toast.addEventListener('mouseleave', this.$swal.resumeTimer)
				},
				icon: 'success',
				title: 'Mis à jour avec succès'
			});
			
            this.$router.push({ name: "Types" });
            // console.log(response2);
        }
    },

    async mounted() {
        const response = await this.$apollo.query({
            query: FindFilter,
            variables: {
                _id: this.typefilterID,
            },
        });
        
        if(!response.loading){this.TypeResponse = response.data.typefilter}

        this.form.input.title = this.TypeResponse.title;
        this.form.input.description = this.TypeResponse.description;
        this.form.input.isActive = this.TypeResponse.isActive;
    },
 }
</script>


<style scoped>

.select-style {
    padding: 7px 1.25rem;
    border: 1px solid #e9e8ee;
    width: 100%;
    height: 40px;
    border-radius: 3px;
    background: #fff;
    position: relative;
}
.select-style select {
    padding: 5px 8px;
    width: 130%;
    border: none;
    box-shadow: none;
    background-color: transparent;
    background-image: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.select-style:after {
    top: 50%;
    left: 85%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(0, 0, 0, 0);
    border-top-color: #000000;
    border-width: 5px;
    margin-top: -2px;
    z-index: 100;
}
.select-style select:focus {
    outline: none;
}


</style>