import gql from "graphql-tag";

export const Deletecodelab = gql`mutation deletecodelab($id: ID!) {
    deletecodelab(id: $id) {
      _id
      bannerImage
      name
      details
      category {
        _id
        name
      }
      status
    }
  }
`