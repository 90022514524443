import gql from "graphql-tag";

export const Codelabs = gql`query codelabs {
  codelabs {
    _id
    name
    filters {
      _id
      value
      createdAt
      typefilter {
        _id
        createdAt
        isActive
        title
        description
      }
    }
    bannerImage
    category {
      _id
      name
    }
    details
    contenue_details
    passwords {
      _id
      pass
    }
    path
    status
  }
}
`

export const FindAllcodelabs = gql`query findAllcodelabs {
  findAllcodelabs {
    _id
    name
    filters {
      _id
      value
      createdAt
      typefilter {
        _id
        createdAt
        isActive
        title
        description
      }
    }
    bannerImage
    category {
      _id
      name
    }
    details
    contenue_details
    passwords {
      _id
      pass
    }
    path
    status
  }
}
`

export const Lab = gql`query codelab($id: ID!) {
  codelab(id: $id) {
    _id
    name
    contenue_details
    bannerImage
    passwords {
      _id
      createdAt
      isActive
      pass
      description
      updatedAt
    }
    filters {
      _id
      value
      createdAt
      typefilter {
        _id
        createdAt
        isActive
        title
        description
      }
    }
  }
}`

export const Codelab = gql`query codelab($id: ID!) {
  codelab(id: $id) {
    _id
    name
    bannerImage
    contenue_details
    details
    passwords {
      _id
      pass
    }
    path
    indexContent
    status
  }
}`