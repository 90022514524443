<template>
	<div class="container-fluid p-4">
		<div class="row">
			<!-- {{ FindFiltersResponse }} -->
			<!-- Page Header -->
			<div class="col-lg-12 col-md-12 col-12">
				<div
					class="
						border-bottom
						pb-4
						mb-4
						d-md-flex
						align-items-center
						justify-content-between
					"
				>
					<div class="mb-3 mb-md-0">
						<h1 class="mb-1 h2 fw-bold">Types de filtres</h1>
					</div>
					<div>
						<a
							href="#"
							class="btn btn-primary"
							data-bs-toggle="modal"
							data-bs-target="#newCatgory"
							>Ajouter un type de filtre</a
						>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-12 col-md-12 col-12">
				<!-- Card -->
				<div class="card mb-4">
					<!-- Card header -->
					<div class="card-header border-bottom-0">
						<span class="mb-1 h4 fw-bold">Liste des types de filtres</span>
						<!-- <form class="d-flex align-items-center">
							<span class="position-absolute ps-3 search-icon">
								<i class="fe fe-search"></i>
							</span>
							<input
								type="search"
								class="form-control ps-6"
								placeholder="Rechercher une catégorie de lab"
							/>
						</form> -->
					</div>
					<!-- Table -->
					<div class="table-responsive border-0 overflow-y-hidden">
						<table class="table mb-0 text-nowrap">
							<thead class="table-light">
								<tr>
									<th class="border-0 font-size-inherit">
										<div
											class="form-check"
											style="visibility: hidden"
										>
											<input
												type="checkbox"
												class="form-check-input"
												id="checkAll"
											/>
											<label
												class="form-check-label"
												for="checkAll"
											></label>
										</div>
									</th>
									<th class="border-0">Noms de types</th>
									<th class="border-0">Description</th>
									<th class="border-0">Créé le</th>
									<th class="border-0">Nbre de filtres</th>
									<th class="border-0">Voir</th>
									<th class="border-0">
										<i class="fe fe-more-vertical"></i>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="typefilter in FindFiltersResponse" :key="typefilter._id">
									<td class="align-middle">
										<div class="form-check">
											<input
												type="checkbox"
												class="form-check-input"
												id="categoryCheck3"
											/>
											<label
												class="form-check-label"
												for="categoryCheck3"
											></label>
										</div>
									</td>
									<td class="align-middle">
										<a class="text-inherit">
											<h5 class="mb-0 text-primary-hover"> {{ typefilter.title }} </h5>
										</a>
									</td>
									<td class="align-middle"> {{ typefilter.description }} </td>
									<td class="align-middle"> {{ typefilter.createdAt.substring(0, 10).split("-").reverse().join("/") }} </td>
									<td class="align-middle"> {{ typefilter.filters.length }} </td>
									<td class="align-middle" v-if="typefilter.filters.length === 0">-</td>
									<td class="align-middle" v-else>
										<a
											@click.prevent="VoirFiltres(typefilter._id)"
											class="btn btn-success btn-sm"
											data-bs-toggle="modal"
											data-bs-target="#voirFiltres"
										>
											<i class="fe fe-eye"></i>
										</a>
									</td>


									<td class="align-middle border-top-0">
										<span class="dropdown dropstart">
											<a
												class="text-decoration-none text-muted"
												role="button"
												id="courseDropdown1"
												data-bs-toggle="dropdown"
												data-bs-offset="-20,20"
												aria-expanded="false"
											>
												<i class="fe fe-more-vertical"></i>
											</a>
											<span
												class="dropdown-menu"
												aria-labelledby="courseDropdown1"
											>
												<span class="dropdown-header">Actions</span>
												<a class="dropdown-item" @click="Updatetype(typefilter._id)"
													><i
														class="fe fe-edit dropdown-item-icon"
													></i
													>Modifier</a
												>
												<a class="dropdown-item"
													data-bs-toggle="modal"
													:data-bs-target="'#supprimer'"
												>
												<i class="fe fe-trash dropdown-item-icon"></i> Supprimer</a>
											</span>
										</span>
									</td>

									<!-- Modal suppression -->
									<div
										class="modal fade"
										:id="'supprimer'"
										tabindex="-1"
										role="dialog"
										aria-labelledby="newLabLabel"
										aria-hidden="true"
									>
										<div class="modal-dialog">
											<div class="modal-content">
												<div class="modal-body text-center">
													<h3 class="modal-title" id="exampleModalCenterTitle">Êtes vous sûr de vouloir supprimer ?</h3>
													<h4 class="modal-title">Cette action est irréversible</h4>
												</div>
												<div class="modal-footer flex"
													style="display: flex; justify-content: space-between; "
												>
													<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
													<button type="button" class="btn btn-danger" @click="remove(typefilter._id)" data-bs-dismiss="modal">
														Supprimer
													</button>
												</div>
											</div>
										</div>
									</div>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>

		<!-- Modal Voir Filtres -->
		<div
			class="modal fade"
			id="voirFiltres"
			tabindex="-1"
			role="dialog"
			aria-labelledby="VoirLabel"
			aria-hidden="true"
		>
			<div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title mb-0" id="VoirLabel">
							Liste les filtres du Type de filtre : <span class="fw-bold text-center" style="color:red"> {{ voirFiltresResponse.title }} </span>
						</h4>
						<button
							type="button"
							class="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
						></button>
					</div>
					<div class="modal-body">
						<div class="fs-4">


							<!-- ===== Modal Voir filtre ======= -->
							<div class="table-responsive border-0 overflow-y-hidden">
								<table class="table mb-0 text-nowrap">
									<thead class="table-light">
										<tr>
											<th scope="col" class="border-0">NOM DES FILTRES</th>
											<th scope="col" class="border-0">Nombre de labs</th>
											<th scope="col" class="border-0">Créé le</th>
											<!-- <th scope="col" class="border-0">Supprimer</th> -->
										</tr>
									</thead>
									<tbody>
										<tr v-for="filter in voirFiltresResponse.filters" :key="filter._id">
                                            <td class="border-top-0">
                                                <a class="text-inherit">
                                                    {{ filter.value }}
                                                </a>
                                            </td>
                                            <td class="border-top-0">
                                                <a class="text-inherit">
                                                    {{ filter.labs.length }}
                                                </a>
                                            </td>
                                            <td class="border-top-0">
                                                <a class="text-inherit">
                                                    {{ filter.createdAt.substring(0, 10).split("-").reverse().join("/") }}
                                                </a>
                                            </td>
											<!-- <td class="align-middle text-center">
												<a
													class="btn btn-danger btn-xs"
													@click="remove(lab._id)"
												>
													<i class="fe fe-trash-2" style="font-size: 1rem; opacity: .7; width: 1.5rem;"></i>
												</a>
											</td> -->
										</tr>
										
									</tbody>
								</table>
							</div>
							<!-- ============ -->

							<div
								class="flex mt-5"
								style="display: flex; justify-content: end"
							>
								<button
									type="button"
									class="btn btn-outline-white"
									data-bs-dismiss="modal"
								>
									Fermer
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- Modal voir Filtres -->
	</div>

	<!-- Modal -->
	<div
		class="modal fade"
		id="newCatgory"
		tabindex="-1"
		role="dialog"
		aria-labelledby="newCatgoryLabel"
		aria-hidden="true"
	>
		<div class="modal-dialog modal-dialog-centered modal-lg">
			<div class="modal-content">
				<div class="modal-header">
					<h4 class="modal-title mb-0" id="newCatgoryLabel">
						Créer une catégorie de labs
					</h4>
					<button
						type="button"
						class="btn-close"
						data-bs-dismiss="modal"
						aria-label="Close"
					></button>
				</div>
				<div class="modal-body">
					<form>
						<div class="mb-3">
							<label class="form-label" for="title"
								>Nom<span class="text-danger">*</span></label
							>
							<input
								type="text"
								class="form-control"
								placeholder="Nom du type de filtre"
								v-model="createTypeFilterInput.title"
								required
							/>
						</div>
						<div class="mb-6">
							<label class="form-label" for="title"
								>Description<span class="text-danger">*</span></label
							>
							<input
								type="text"
								class="form-control"
								placeholder="Description du type de lab"
								v-model="createTypeFilterInput.description"
								required
							/>
						</div>
						<div
							class="flex"
							style="display: flex; justify-content: space-between"
						>
							<button
								type="button"
								class="btn btn-outline-white"
								data-bs-dismiss="modal"
							>
								Fermer
							</button>
							<button
								@click.prevent="addTypeFiltre()"
								data-bs-dismiss="modal"
								type="submit"
								class="btn btn-primary"
								style="margin-right: 5px"
							>
								<!-- onclick="window.location.reload(false)" -->
								Enregistrer
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { FindFilters } from "../../gql/queries/typefilters"
import { FindFilter } from "../../gql/queries/typefilters"
import { createTypeFilter } from "../../gql/mutations/createTypeFilter"
import { RemoveTypeFilter } from "../../gql/mutations/removeTypeFilter"
export default {

	data() {
		return {
			FindFilters,
			FindFiltersResponse: null,
			voirFiltresResponse: {},
			FindFilter,
			createTypeFilter,
			RemoveTypeFilter,
			createTypeFilterInput: {
				isActive: true,
			},
		};
	},

	methods: {
		async VoirFiltres(FiltreTypeID){
			const voirFiltres = await this.$apollo.query({
				query: FindFilter,
				variables:{
					_id : FiltreTypeID
				},
				})
			if (!voirFiltres.loading){this.voirFiltresResponse = voirFiltres.data.typefilter}
		},

		async addTypeFiltre(){
			await this.$apollo.mutate({
				mutation: createTypeFilter,
				variables: {
					createTypeFilterInput: this.createTypeFilterInput
				}
			})
			this.$swal({
				toast: true,
				position: 'top-end',
				showConfirmButton: false,
				timer: 1200,
				timerProgressBar: true,
				didOpen: (toast) => {
					toast.addEventListener('mouseenter', this.$swal.stopTimer)
					toast.addEventListener('mouseleave', this.$swal.resumeTimer)
				},
				icon: 'success',
				title: 'Ajouté avec succès'
			});
			
            this.$router.push({ name: "Types" });
            setTimeout(function(){
                window.location.reload(false);
            }, 1000);
		},

		remove(id) {
			this.$apollo.mutate({
				mutation: RemoveTypeFilter,

				variables: {
					_id: id,
				}
			})
			window.location.reload(false)
		},

		Updatetype(typefilterID){
            this.$router.push({
                name: 'Updatetypes',
                params: {
                    id: typefilterID
                }
            })
        },
	},
		


	async mounted(){
		const response = await this.$apollo.query({
			query:FindFilters
		})
		// console.log(response)

		if(!response.loading){this.FindFiltersResponse = response.data.typeFilters}
	},
}


</script>