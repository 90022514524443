import { ApolloClient, InMemoryCache,ApolloLink  } from '@apollo/client/core';
// import { setContext } from '@apollo/client/link/context';
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from 'apollo-upload-client'



let uri = process.env.VUE_APP_ROOT_API;

const getToken = ()=>{
  return localStorage.getItem('token') ? {ContentType:'Application/json',Authorization:`Bearer ${localStorage.getItem("token")}`}:{ContentType:'Application/json'}
}
const headers = getToken();

  
// const httpLink = new HttpLink({
//     // You should use an absolute URL here
//     // uri: 'https://scem-preprod.oa.r.appspot.com/api/v1/graphql',
//     uri,
//     headers
// }) 

  const errorLink = onError(({ graphQLErrors, networkError,forward,operation }) => {
    if (graphQLErrors){
      for(let err of graphQLErrors){
        switch(err.extensions.code){
          case 'UNAUTHENTICATED':
            operation.setContext({
              headers:{
                authorization:`Bearer ${localStorage.getItem("token")}`
              }
            })

            return forward(operation)
        }
      }
      graphQLErrors.forEach(({ message}) => message);
    }
     
  
    if (networkError) console.log(`[Network error]: ${networkError}`);

    forward(operation)
  });

  const link = ApolloLink.from([
    errorLink,
    createUploadLink({
      uri,
      headers
    })
  ])

  
  const apolloClient = new ApolloClient({
      link,
      cache: new InMemoryCache({
        typePolicies:{
          Query:{
            fields:{
              admins:{
                merge(existing,incoming){
                  return incoming;
                }
              }
            }
          }
        }
      }),
      connectToDevTools: true,
  })
  
  export default apolloClient
