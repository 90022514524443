import { createRouter, createWebHashHistory } from 'vue-router';
import Dashboard from '../pages/Dashboard.vue';
import Login from '../pages/Login.vue';
import Home from '../components/Layouts/Home.vue';
import Labs from '../pages/codelabs/Labs.vue';
import Add_lab from '../pages/codelabs/Add_lab.vue';
import Updatelab from '../pages/codelabs/Updatelab.vue';
import Updateziplab from '../pages/codelabs/Updateziplab.vue';
import AddNewFilter from '../pages/codelabs/AddNewFilter.vue';
import Passwords from '../pages/Passwords/Passwords.vue';
import Add_password from '../pages/Passwords/Add_password.vue';
import Categories from '../pages/Categories.vue';
import Types from '../pages/TypeFiltres/Types.vue';
import Updatetypes from '../pages/TypeFiltres/Updatetypes.vue';
import Filtres from '../pages/Filtres/Filtres.vue';
import Addfiltre from '../pages/Filtres/Addfiltre.vue';
import Updatefiltre from '../pages/Filtres/Updatefiltre.vue';
import Abonnements from "../pages/Abonnements/Abonnements.vue";
import Subscriptions from "../pages/Subscriptions/Subscriptions.vue";
import AddAbonnement from "../pages/Abonnements/AddAbonnement.vue";
import Updateabonnement from "../pages/Abonnements/Updateabonnement.vue";
import Users from '../pages/Users/Users.vue';
import Adduser from '../pages/Users/Adduser.vue';
import Students from "../pages/Students/Students.vue";
import Addstudent from "../pages/Students/AddStudent.vue";

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: { layout: 'empty' },
  },
  {
    path: '/admin',
    name: 'Home',
    component: Home,
    meta: { layout: 'empty' },
    redirect: 'admin/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: { layout: 'empty' },
      },
      {
        path: '/labs',
        name: 'Labs',
        component: Labs,
        meta: { layout: 'empty' },
      },
      {
        path: '/addlab',
        name: 'Addlab',
        component: Add_lab,
        meta: { layout: 'empty' },
      },
      {
        path: '/addnewfilter',
        name: 'addnewfilter',
        component: AddNewFilter,
        meta: { layout: 'empty' },
      },
      {
        path: '/passwords',
        name: 'Passwords',
        component: Passwords,
        meta: { layout: 'empty' },
      },
      {
        path: '/addpassword',
        name: 'Addpassword',
        component: Add_password,
        meta: { layout: 'empty' },
      },
      {
        path: '/updatelab/:id',
        name: 'Updatelab',
        component: Updatelab,
        meta: { layout: 'empty' },
      },
      {
        path: '/updateziplab/:id',
        name: 'Updateziplab',
        component: Updateziplab,
        meta: { layout: 'empty' },
      },
      {
        path: '/categories',
        name: 'Categories',
        component: Categories,
        meta: { layout: 'empty' },
      },
      {
        path: '/types',
        name: 'Types',
        component: Types,
        meta: { layout: 'empty' },
      },
      {
        path: '/updatetypes/:id',
        name: 'Updatetypes',
        component: Updatetypes,
        meta: { layout: 'empty' },
      },
      {
        path: '/filtres',
        name: 'Filtres',
        component: Filtres,
        meta: { layout: 'empty' },
      },
      {
        path: '/addfiltre',
        name: 'Addfiltre',
        component: Addfiltre,
        meta: { layout: 'empty' },
      },
      {
        path: '/updatefiltre:id',
        name: 'Updatefiltre',
        component: Updatefiltre,
        meta: { layout: 'empty' },
      },
      {
        path: '/abonnements',
        name: 'Abonnements',
        component: Abonnements,
        meta: { layout: 'empty' },
      },
      {
        path: '/addabonnement',
        name: 'AddAbonnement',
        component: AddAbonnement,
        meta: { layout: 'empty' },
      },
      {
        path: '/updateabonnement:id',
        name: 'Updateabonnement',
        component: Updateabonnement,
        meta: { layout: 'empty' },
      },
      {
        path: '/subscriptions',
        name: 'Subscriptions',
        component: Subscriptions,
        meta: { layout: 'empty' },
      },
      {
        path: '/users',
        name: 'Users',
        component: Users,
        meta: { layout: 'empty' },
      },
      {
        path: '/students',
        name: 'Students',
        component: Students,
        meta: { layout: 'empty' },
      },
      {
        path: '/addstudents',
        name: 'Addstudents',
        component: Addstudent,
        meta: { layout: 'empty' },
      },
      {
        path: '/addusers',
        name: 'Adduser',
        component: Adduser,
        meta: { layout: 'empty' },
      },
    ],
  },
];

let router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
