import gql from "graphql-tag";

export const Users = gql`query admins{
    admins {
      _id
      createdAt
      email
      fullname
      phone
      role
      status
    }
}`


export const Students = gql`query { 
  users{
    _id
    name
    lastname
    role
    email
    birth
    phone_number
    createdAt
    status
    lastSubscriptionEnd
    lastConnexion
    mailWasConfirmed
    subscriptionStart
    subscriptions {
      _id
      start
      end
      status
      paymentToken
      abonnement {
        _id
        libelle
        description
        price
        validity
      }
    }
	}
}`

export const StudentsAsActiveSubscription = gql `query {
  userWithActiveSubscriptions {
    _id
    birth
    createdAt
    email
    lastConnexion
    lastSubscriptionEnd
    lastname
    mailWasConfirmed
    name
    phone_number
    role
    status
    subscriptionStart
    subscriptions{_id start end paymentToken isActive}
  }
}
`

