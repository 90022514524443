import gql from "graphql-tag";

export const createCategorie = gql`mutation createCategorie($categoryInput: CreateCategoryInput!) {
    createCategorie(categoryInput: $categoryInput) {
      _id
      name
      lab{_id name}
    }
  }
  `
