<template>
	<div class="container-fluid p-4">
		<div class="row">
			<!-- Page Header -->
			<div class="col-lg-12 col-md-12 col-12">
				<div
					class="
						border-bottom
						pb-4
						mb-4
						d-md-flex
						align-items-center
						justify-content-between
					"
				>
					<div class="mb-3 mb-md-0">
						<h1 class="mb-1 h2 fw-bold">Gestion des etudiants</h1>
					</div>
					<div>
						<a class="btn btn-primary" @click="$router.push({ name: 'Addstudents' })">
							Ajouter un étudiant
						</a>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-12 col-md-12 col-12">
				<!-- Card -->
				<div class="card mb-4">
					<!-- Card header -->
					<div class="card-header border-bottom-0">
						<span class="mb-1 h4 fw-bold">Liste des étudiants</span>
						<form class="mt-2 d-flex align-items-center">
							<span class="position-absolute ps-3 search-icon">
								<i class="fe fe-search"></i>
							</span>
							<input
								type="search"
								class="form-control ps-6"
								placeholder="Rechercher un étudiant"
								v-model="filterdText"
							/>
						</form>
					</div>
					<!-- Table -->
					<div class="table-responsive border-0 overflow-y-hidden">
						<table class="table mb-0 text-nowrap">
							<thead class="table-light">
								<tr>
									<th class="border-0 font-size-inherit">
										<div
											class="form-check"
											style="visibility: hidden"
										>
											<input
												type="checkbox"
												class="form-check-input"
												id="checkAll"
											/>
											<label
												class="form-check-label"
												for="checkAll"
											></label>
										</div>
									</th>
									<th class="border-0">Nom & Prénoms</th>
									<th class="border-0">Email</th>
									<th class="border-0">Dte Naiss</th>
									<th class="border-0">Portable</th>
									<th class="border-0">Status mail</th>
									<th class="border-0">Dernière Co°</th>
									<th class="border-0">Abonnement</th>
									<th class="border-0">
										<i class="fe fe-more-vertical"></i>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="user in filteredStudents" :key="user._id">
									<td class="align-middle">
										<div class="form-check">
											<input
												type="checkbox"
												class="form-check-input"
												id="categoryCheck3"
											/>
											<label
												class="form-check-label"
												for="categoryCheck3"
											></label>
										</div>
									</td>
									<td class="align-middle">
										<a class="text-inherit">
											<h5 class="mb-0 text-primary-hover">{{ user.name  }} {{ user.lastname  }}</h5>
										</a>
									</td>
									<td class="align-middle"> <h5 class="mb-0">{{ user.email }}</h5> </td>
									<td class="align-middle"> <h5 class="mb-0">{{ user.birth }}</h5> </td>
									<td class="align-middle">{{ user.phone_number }}</td>
									<td class="align-middle">
										<span v-if="user.mailWasConfirmed === true" class="badge bg-success">Vérifié</span>
										<span v-else class="badge bg-warning">En attente</span>
									</td>
									<td class="align-middle"> <h5 class="mb-0">{{ user.lastConnexion || "-" }}</h5> </td>
									<td class="align-middle">
										<span v-if="user.lastSubscriptionEnd" class="badge bg-success"> <h6 class="mb-0">Du <strong>{{ user.lastSubscriptionEnd }}</strong> Au <strong>{{ user.lastSubscriptionEnd }}</strong></h6></span>
										<span v-else class="badge bg-secondary">Aucun</span>
									</td>
									<td class="align-middle border-top-0">
										<span class="dropdown dropstart">
											<a
												class="text-decoration-none text-muted"
												role="button"
												id="courseDropdown1"
												data-bs-toggle="dropdown"
												data-bs-offset="-20,20"
												aria-expanded="false"
											>
												<i class="fe fe-more-vertical"></i>
											</a>
											<span
												class="dropdown-menu"
												aria-labelledby="courseDropdown1"
											>
												<span class="dropdown-header">Actions</span>
												<!-- <a class="dropdown-item"
													><i
														class="fe fe-edit dropdown-item-icon"
													></i
													>Modifier</a
												> -->
												<a class="dropdown-item"
													data-bs-toggle="modal"
													:data-bs-target="'#supprimer' + user._id"
												>
												<i class="fe fe-trash dropdown-item-icon"></i> Supprimer</a>
											</span>
										</span>
									</td>

									<!-- Modal suppression -->
									<div
										class="modal fade"
										:id="'supprimer' + user._id"
										tabindex="-1"
										role="dialog"
										aria-labelledby="newLabLabel"
										aria-hidden="true"
									>
										<div class="modal-dialog">
											<div class="modal-content">
												<div class="modal-body text-center">
													<h3 class="modal-title" id="exampleModalCenterTitle">Êtes vous sûr de vouloir supprimer ?</h3>
													<h4 class="modal-title">Cette action est irréversible !</h4>
												</div>
												<div class="modal-footer flex"
													style="display: flex; justify-content: space-between; "
												>
													<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
													<button type="button" class="btn btn-danger" @click="remove(user._id)" data-bs-dismiss="modal" onclick="window.location.reload(false)">
														Supprimer
													</button>
												</div>
											</div>
										</div>
									</div>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import {Students } from '../../gql/queries/users'
import { removeStudent } from '../../gql/mutations/createStudent'

export default {
	data() {
		return {
			Students,
            StudentsResponse : null,
			filterdText:''
		}
	},

	computed:{
		filteredStudents(){
          if(this.filterdText){
            return this.StudentsResponse.filter(user => 
              user.name.toLowerCase().includes(this.filterdText.toLowerCase()) ||
              user.lastname.toLowerCase().includes(this.filterdText.toLowerCase()) ||
              user.email.toLowerCase().includes(this.filterdText.toLowerCase())
            )
          }else{
            return this.StudentsResponse
          }
        }
	},
	
	methods: {
		remove(userID){
            
			this.$apollo.mutate({
				mutation: removeStudent,

				variables: {
					_id: userID,
				}
			})

		}
	},

	async mounted() {
        const response = await this.$apollo.query({
            query:Students
        })
        
        if(!response.loading){this.StudentsResponse = response.data.users}
	},
}
</script>