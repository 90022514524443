import gql from "graphql-tag";

export const Categories = gql`query {
    categories {
      _id
      name
      lab{_id name}
    }
}`

