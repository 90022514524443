<template>
	<div class="container-fluid p-4">
		<div class="row">
			<!-- Page Header -->
			<div class="col-lg-12 col-md-12 col-12">
				<div
					class="
						border-bottom
						pb-4
						mb-4
						d-md-flex
						align-items-center
						justify-content-between
					"
				>
					<div class="mb-3 mb-md-0">
						<h1 class="mb-1 h2 fw-bold">
							Modifier le filtre
							<!-- <span class="fs-5">(210 Courses)</span> -->
						</h1>
					</div>
					<div>
						<a
							class="btn btn-secondary"
							@click="$router.push({ name: 'Filtres' })"
						>
							Retour
						</a>
					</div>
				</div>
			</div>
		</div>
		<div class="row justify-content-md-center">
			<!-- {{labDetailsInput}} -->
			<div class="col-lg-8 col-md-8 col-12">
				<!-- Card -->
				<div class="card mb-4">
					<!-- Card Header -->
					<div class="modal-body">
						<form>

							<div class="mb-3">
								<label class="form-label" for="title">
									Nom du filtre <span class="text-danger">*</span>
								</label>
								<input
                                    v-model="form.input.value"
									type="text"
									class="form-control"
									placeholder="Nom du type de filtre"
									required
								/>
							</div>

                            <div class="mb-3">
								<label class="form-label" for="title">
									Catégorie du filtre <span class="text-danger">*</span>
								</label>
								<select 
                                    v-model="form.input.typefilterID"
									class="select-style" data-width="100%"
									required
								>
									<option>Selectionner une catégorie de filtre</option>
									<option v-for="typeFilter in TypeFiltersResponse" :key="typeFilter._id" :value="typeFilter._id"> 
										{{typeFilter.title}}
									</option>

								</select>
								<small> Selectionner une catégorie de filtre </small>
							</div>

							<div
								class="flex"
								style="display: flex; justify-content: end"
							>
								
								<button
                                    @click.prevent="updateFiltre()"
									type="submit"
									class="btn btn-primary"
									style="margin-right: 5px"
								>
									Enregistrer
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Filtre } from "../../gql/queries/filtres"
import { FindFilters } from "../../gql/queries/typefilters"
import { UpdateFilter } from "../../gql/mutations/createFilter"

export default { 
    data() {
        return {
            Filtre,
            FindFilters,
            TypeFiltersResponse: null,
            filtreID: this.$route.params.id,
            form: {
                input: {
                    value: null,
                    typefilterID: null,
                },
            },
            UpdateFilter

        }
    },

    methods: {
        async updateFiltre() {
            // const response2 = 
            await this.$apollo.mutate({
				mutation: UpdateFilter,
				variables: {
                    _id: this.filtreID,
                    updateFilterInput: {
                        filterType: this.form.input.typefilterID,
                        value: this.form.input.value
                    }
				},
			})
            console.log(this.$apollo.mutate);
            this.$swal({
				toast: true,
				position: 'top-end',
				showConfirmButton: false,
				timer: 1200,
				timerProgressBar: true,
				didOpen: (toast) => {
					toast.addEventListener('mouseenter', this.$swal.stopTimer)
					toast.addEventListener('mouseleave', this.$swal.resumeTimer)
				},
				icon: 'success',
				title: 'Mis à jour avec succès'
			});
			
            this.$router.push({ name: "Filtres" });
			// setTimeout(function(){
            //     window.location.reload(false);
            // }, 1000);
        }
    },

    async mounted() {
        const [response, response2] = await Promise.all([
            await this.$apollo.query({
                query: Filtre,
                variables: {
                    _id: this.filtreID,
                },
            }),

            await this.$apollo.query({
                query: FindFilters,
            }),
        ])
        
        
        if(!response.loading){this.FiltreResponse = response.data.findFilter}
        this.form.input.value = this.FiltreResponse.value;
        this.form.input.typefilterID = this.FiltreResponse.typefilter._id;

        if(!response2.loading){this.TypeFiltersResponse = response2.data.typeFilters}
    },
 }
</script>


<style scoped>

.select-style {
    padding: 7px 1.25rem;
    border: 1px solid #e9e8ee;
    width: 100%;
    height: 40px;
    border-radius: 3px;
    background: #fff;
    position: relative;
}
.select-style select {
    padding: 5px 8px;
    width: 130%;
    border: none;
    box-shadow: none;
    background-color: transparent;
    background-image: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.select-style:after {
    top: 50%;
    left: 85%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(0, 0, 0, 0);
    border-top-color: #000000;
    border-width: 5px;
    margin-top: -2px;
    z-index: 100;
}
.select-style select:focus {
    outline: none;
}


</style>