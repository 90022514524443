import gql from "graphql-tag";

export const createPassword = gql`mutation createPassword($createPasswordInput: CreatePasswordDto!) {
    createPassword(createPasswordInput: $createPasswordInput) {
      _id
      createdAt
      pass
      description
    }
}`

export const RemovePassword = gql`mutation removePassword($id: ID!) {
  removePassword(id: $id) {
    _id
    lab {
      _id
      name
    }
  }
}`