import gql from "graphql-tag";

export const Login = gql `mutation login($input: LoginInput!) {
    login(input: $input) {
      access_token
      duration
      success
      userData {
        _id
        fullname
        role
        email
      }
    }
  }
`