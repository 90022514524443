
import gql from "graphql-tag";
export const CreateStudent = gql`mutation createuser($createUserInput: CreateUserInput!) {
    createuser(createUserInput: $createUserInput) {
      _id
      birth
      name
      lastname
      phone_number
      role
      email
      createdAt
    }
}`


export const removeStudent = gql`mutation removeuser($_id: ID!) {
    removeuser(_id: $_id) {
        _id
        name
        lastname
    }
  }
`