import gql from 'graphql-tag';

// export const Createcodelab = gql`mutation createcodelab(
//   $bannerImage: Upload!
//   $file: Upload!
//   $createLabInput: CreateLabInput!
//   $labDetailsInput: LabDetailsInput!
// ) {
//   createcodelab(
//     bannerImage: $bannerImage
//     file: $file
//     createLabInput: $createLabInput
//     labDetailsInput: $labDetailsInput
//   ) {
//     _id
//     name
//     path
//     bannerImage
//     status
//   }
// }`

export const Createcodelab = gql`
  mutation (
    $file: Upload!
    $createLabInput: CreateLabInput!
    $labDetailsInput: LabDetailsInput!
    $bannerImage: Upload!
  ) {
    createcodelab(
      bannerImage: $bannerImage
      file: $file
      createLabInput: $createLabInput
      labDetailsInput: $labDetailsInput
    ) {
      _id
      name
      details
      bannerImage
      contenue_details
      path
      status
      storageFolder
    }
  }
`;

export const Updatecodelab = gql`
  mutation updatecodelab($id: ID!, $updateLabInput: UpdateLabInput!) {
    updatecodelab(id: $id, updateLabInput: $updateLabInput) {
      _id
      name
      details
      contenue_details
      indexContent
      passwords {
        _id
        pass
      }
      path
      status
    }
  }
`;
