<template>
    <div id="db-wrapper">
        <!-- Left Sidenav -->
        <LeftSidenav/>

        <div id="page-content">

            <div class="header">

                <!-- Navbar -->
                <TopBar/>

            </div>

            <!-- APP CONTENT -->
            <router-view></router-view>

        </div>
    </div>
</template>
<script>
import TopBar from "../TopBar.vue";
import LeftSidenav from "../LeftSidenav.vue";
export default {
    components:{
        LeftSidenav,
        TopBar,
    }
}
</script>
<style>
</style>
