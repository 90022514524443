<template>
	<div class="container-fluid p-4">
		<div class="row">
			<!-- Page Header -->
			<div class="col-lg-12 col-md-12 col-12">
				<div
					class="
						border-bottom
						pb-4
						mb-4
						d-md-flex
						align-items-center
						justify-content-between"
				>
					<div class="mb-3 mb-md-0">
						<h1 class="mb-1 h2 fw-bold">
							Liste des labs
							<!-- <span class="fs-5">(210 Courses)</span> -->
						</h1>
					</div>
					<div>
						<a
							class="btn btn-outline-secondary"
							style="margin-right: 10px"
							@click="$router.push({ name: 'addnewfilter' })"
						>
							Associer un filtre à un lab
						</a>
						<a
							class="btn btn-primary"
							@click="$router.push({ name: 'Addlab' })"
							onclick="window.location.reload(false)"
						>
							Ajouter un lab
						</a>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-12 col-md-12 col-12">
				<!-- Card -->
				<div class="card mb-4">
					<!-- Card Header -->
					<div class="card-header border-bottom-0">
						<form class="d-flex align-items-center">
							<span class="position-absolute ps-3 search-icon">
								<i class="fe fe-search"></i>
							</span>
							<input
								type="search"
								class="form-control ps-6"
								placeholder="Rechercher un lab"
								v-model="filterdText"
							/>
						</form>
					</div>
					<!-- Table  -->
					<div class="table-responsive border-0 overflow-y-hidden">
						<table class="table mb-0 text-nowrap">
							<thead class="table-light">
								<tr>
									<th scope="col" class="border-0">LABS</th>
									<th scope="col" class="border-0">ETAT</th>
									<th scope="col" class="border-0">NIVEAU</th>
									<th scope="col" class="border-0">TEMPS</th>
									<th scope="col" class="border-0">FILTRES</th>
									<th scope="col" class="border-0">DÉTAILS</th>
									<th scope="col" class="border-0">STATUT</th>
									<th scope="col" class="border-0">
										<i class="fe fe-more-vertical"></i>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="codelab in filteredLabs"
									:key="codelab._id"
								>
									<td class="border-top-0" style="width: 30%">
										<a class="text-inherit">
											<div class="d-lg-flex align-items-center">
												<div>
													<img
														v-if="codelab.bannerImage === null"
														src="https://formations.jkp-dev.com/images/project-1.png"
														alt=""
														class="img-4by3-lg rounded"
													/>
													<img
														v-else
														:src="
															getImagebanner(codelab.bannerImage)
														"
														alt=""
														class="img-4by3-lg rounded"
													/>
												</div>
												<div
													class="
														ms-lg-3
														mt-2 mt-lg-0
														text-truncate
													"
													style="width: 180px"
												>
													<h4 class="mb-1 text-primary-hover">
														{{ codelab.name }}
													</h4>
													<span
														class="text-inherit text-truncate"
														>{{
															codelab.details?.description
														}}</span
													>
												</div>
											</div>
										</a>
									</td>
									<td
										v-if="codelab.passwords.length === 0"
										class="align-middle border-top-0"
									>
										<div class="d-flex align-items-center">
											<i
												class="fe fe-lock text-success fs-4 me-2"
											></i>
											<h5 class="mb-0 text-success">Libre</h5>
										</div>
									</td>
									<td v-else class="align-middle border-top-0">
										<div class="d-flex align-items-center">
											<i
												class="fe fe-lock text-danger fs-4 me-2"
											></i>
											<h5 class="mb-0 text-danger">Protégé</h5>
										</div>
									</td>
									<td class="align-middle border-top-0">
										<h5 v-if="codelab.details?.level === 'BEGINNER'">
											Débutant
										</h5>
										<h5
											v-if="
												codelab.details?.level === 'INTERMEDIATE'
											"
										>
											Intermmédiaire
										</h5>
										<h5 v-if="codelab.details?.level === 'ADVANCED'">
											Avancé
										</h5>
									</td>
									<td class="align-middle border-top-0">
										<h5>{{ codelab.details?.time }}min</h5>
									</td>
									<td class="align-middle">
										<a
											@click.prevent="VoirFiltres(codelab._id)"
											class="btn btn-success btn-sm"
											data-bs-toggle="modal"
											data-bs-target="#voirFiltres"
										>
											<i class="fe fe-eye"></i>
										</a>
									</td>
									<td class="align-middle">
										<a
											@click.prevent="VoirDetails(codelab._id)"
											class="btn btn-success btn-sm"
											data-bs-toggle="modal"
											data-bs-target="#voirDetails"
										>
											<i class="fe fe-eye"></i>
										</a>
									</td>
									<td
										class="align-middle"
										v-if="codelab.status === true"
									>
										<span
											class="
												badge-dot
												bg-success
												me-1
												d-inline-block
												align-middle
											"
										></span>
										Activé
									</td>
									<td class="align-middle" v-else>
										<span
											class="
												badge-dot
												bg-danger
												me-1
												d-inline-block
												align-middle
											"
										></span>
										Désactivé
									</td>
									<td class="align-middle border-top-0">
										<span class="dropdown dropstart">
											<a
												class="text-decoration-none text-muted"
												role="button"
												id="courseDropdown1"
												data-bs-toggle="dropdown"
												data-bs-offset="-20,20"
												aria-expanded="false"
											>
												<i class="fe fe-more-vertical"></i>
											</a>
											<span
												class="dropdown-menu"
												aria-labelledby="courseDropdown1"
											>
												<span class="dropdown-header">Actions</span>
												<a
													class="dropdown-item"
													@click="Updatelab(codelab._id)"
													><i
														class="fe fe-edit dropdown-item-icon"
													></i
													>Modifier</a
												>
												<a
													class="dropdown-item"
													@click="Updatezip(codelab._id)"
													><i
														class="fe fe-edit dropdown-item-icon"
													></i
													>Modifier Zip</a
												>
												<a
													class="dropdown-item"
													data-bs-toggle="modal"
													:data-bs-target="
														'#supprimer' + codelab._id
													"
												>
													<i
														class="fe fe-trash dropdown-item-icon"
													></i>
													Supprimer</a
												>
											</span>
										</span>
									</td>

									<!-- Modal suppression -->
									<div
										class="modal fade"
										:id="'supprimer' + codelab._id"
										tabindex="-1"
										role="dialog"
										aria-labelledby="newLabLabel"
										aria-hidden="true"
									>
										<div class="modal-dialog">
											<div class="modal-content">
												<div class="modal-body text-center">
													<h3
														class="modal-title"
														id="exampleModalCenterTitle"
													>
														Êtes vous sûr de vouloir supprimer ?
													</h3>
													<h4 class="modal-title">
														Cette action est irréversible
													</h4>
												</div>
												<div
													class="modal-footer flex"
													style="
														display: flex;
														justify-content: space-between;
													"
												>
													<button
														type="button"
														class="btn btn-secondary"
														data-bs-dismiss="modal"
													>
														Annuler
													</button>
													<button
														type="button"
														class="btn btn-danger"
														@click="remove(codelab._id)"
														data-bs-dismiss="modal"
													>
														Supprimer
													</button>
												</div>
											</div>
										</div>
									</div>
								</tr>
							</tbody>
						</table>
					</div>
					<!-- Card Footer -->
					<!-- <div class="card-footer border-top-0">
						<nav aria-label="Page navigation example">
							<ul class="pagination justify-content-center mb-0">
								<li class="page-item disabled">
									<a
										class="page-link mx-1 rounded"
										tabindex="-1"
										aria-disabled="true"
										><i class="mdi mdi-chevron-left"></i
									></a>
								</li>
								<li class="page-item active">
									<a class="page-link mx-1 rounded">1</a>
								</li>
								<li class="page-item">
									<a class="page-link mx-1 rounded">2</a>
								</li>
								<li class="page-item">
									<a class="page-link mx-1 rounded">3</a>
								</li>
								<li class="page-item">
									<a class="page-link mx-1 rounded"
										><i class="mdi mdi-chevron-right"></i
									></a>
								</li>
							</ul>
						</nav>
					</div> -->
				</div>
			</div>
		</div>

		<!-- Modal Voir Filtres -->
		<div
			class="modal fade"
			id="voirFiltres"
			tabindex="-1"
			role="dialog"
			aria-labelledby="VoirLabel"
			aria-hidden="true"
		>
			<div
				class="
					modal-dialog
					modal-dialog-centered
					modal-lg
					modal-dialog-scrollable
				"
			>
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title mb-0" id="VoirLabel">
							Liste des Filtres du lab :
							<span class="fw-bold text-center" style="color: red">
								{{ FiltresLabResponse.name }}
							</span>
						</h4>
						<button
							type="button"
							class="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
						></button>
					</div>
					<div class="modal-body">
						<div class="fs-4">
							<!-- ===== Modal Voir Mot de passes ======= -->
							<div class="table-responsive border-0 overflow-y-hidden">
								<table class="table mb-0 text-nowrap">
									<thead class="table-light">
										<tr>
											<th scope="col" class="border-0"></th>
											<th scope="col" class="border-0">
												TYPE DE FILTRE
											</th>
											<th scope="col" class="border-0">Valeur</th>
											<th scope="col" class="border-0">Créé le</th>
											<th scope="col" class="border-0">Supprimer</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="(filter, index) in FiltresLabResponse.filters"
											:key="filter._id"
										>
											<td>
												<h5>{{ index + 1 }}</h5>
											</td>
											<td class="align-middle border-top-0">
												<h5>{{ filter.value }}</h5>
											</td>
											<!-- <td class="align-middle border-top-0" v-if="filter.filterType === 'DURATION'"><h5>DURRÉE</h5></td>
											<td class="align-middle border-top-0" v-if="filter.filterType === 'FIELD'"><h5>DOMAINE</h5></td>
											<td class="align-middle border-top-0" v-if="filter.filterType === 'LEVEL'"><h5>NIVEAU</h5></td>
											<td class="align-middle border-top-0" v-if="filter.filterType === 'PRICE'"><h5>PRIX</h5></td>
											<td class="align-middle border-top-0" v-if="filter.filterType === 'TOPIC'"><h5>SUJET</h5></td> -->
											<td>
												<h5>{{ filter.value }}</h5>
											</td>
											<td>
												{{
													filter.createdAt
														.substring(0, 10)
														.split("-")
														.reverse()
														.join("/")
												}}
											</td>
											<td class="align-middle text-center">
												<a
													class="btn btn-danger btn-xs"
													@click="removeFilter(filter._id)"
												>
													<i class="fe fe-trash-2" style="font-size: 1rem; opacity: .7; width: 1.5rem;"></i>
												</a>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<!-- ============ -->

							<div
								class="flex mt-5"
								style="display: flex; justify-content: end"
							>
								<button
									type="button"
									class="btn btn-outline-white"
									data-bs-dismiss="modal"
								>
									Fermer
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- Modal voir Filtres -->

		<!-- Modal Voir Détails -->
		<div
			class="modal fade"
			id="voirDetails"
			tabindex="-1"
			role="dialog"
			aria-labelledby="VoirDetail"
			aria-hidden="true"
		>
			<div
				class="
					modal-dialog
					modal-dialog-centered
					modal-xl
					modal-dialog-scrollable
				"
			>
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title mb-0" id="VoirDetail">
							Liste la page de détails du lab :
							<span class="fw-bold text-center" style="color: red">
								{{ codelab?.name }}
							</span>
						</h4>
						<button
							type="button"
							class="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
						></button>
					</div>
					<div class="modal-body container mt-4">
						<!-- Page de details du lab -->

						<div class="row">
							<div class="col-lg-8 col-md-12 col-12 mb-4 mb-lg-0">
								<!-- Card -->
								<div class="card rounded-3">
									<!-- Card header -->
									<div class="card-header border-bottom-0 p-0">
										<div>
											<!-- Nav -->
											<ul class="nav nav-lb-tab" id="tab" role="tablist">
												<li class="nav-item">
													<a class="nav-link active" id="description-tab" data-bs-toggle="pill" href="#description" role="tab" aria-controls="description" aria-selected="true">Description</a>
												</li>
												<li class="nav-item">
													<a class="nav-link" id="table-tab" data-bs-toggle="pill" href="#table" role="tab" aria-controls="table" aria-selected="false">Contenu</a>
												</li>
												
											</ul>
										</div>
									</div>
									<!-- Card Body -->
									<div class="card-body">
										<div class="tab-content" id="tabContent">
											<div class="tab-pane fade" id="table" role="tabpanel" aria-labelledby="table-tab">
												<!-- Card -->
												<div class="accordion" id="courseAccordion">
													<div>
														<!-- List group -->
														<ul class="list-group list-group-flush">
															<li class="list-group-item px-0" v-for="(contenu, index) in details?.contenue" :key="contenu.titre">
																<!-- Toggle -->
																<a v-if="contenu?.soustitre?.length >= 1" class=" h4 mb-0 d-flex align-items-center text-inherit text-decoration-none" data-bs-toggle="collapse" :href="'#mood'+index" aria-expanded="false" :aria-controls="'mood'+index">
																	<div class="me-auto">
																		{{contenu?.titre}}
																	</div>
																	<!-- Chevron -->
																	<span class="chevron-arrow ms-4">
																		<i class="fe fe-chevron-down fs-4"></i>
																	</span>
																</a>
																<a v-else class=" h4 mb-0 ">
																	<div class="me-auto">
																		{{contenu?.titre}}
																	</div>
																</a>
																<!-- Row -->
																<!-- Collapse -->
																<div class="collapse" :id="'mood'+index" data-bs-parent="#courseAccordion">
																	<div class="pt-3 pb-2">
																		<a
																			class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none"
																			v-for="soustitre in contenu?.soustitre"
																			:key="soustitre"
																			>
																			<div class="">
																				<span>{{soustitre}}</span>
																			</div>
																		</a>
																		
																	</div>
																</div>
															</li>
															
														</ul>
													</div>
												</div>
											</div>
											<div class="tab-pane fade show active" id="description" role="tabpanel" aria-labelledby="description-tab">
												<!-- Description -->
												<div class="mb-4">
													<h3 class="mb-2">Description du cours</h3>
													<p>
														{{details?.description?.split("%")[0]}}.
													</p>
												</div>
												<h4 class="mb-3">Ce que vous apprendrez</h4>
												<div class="row mb-3">
													<div class="col-12 col-md-6">
														<ul class="list-unstyled">
															<li class="d-flex mb-2" v-for="toLearn in details?.toLearn" :key="toLearn">
																<i class="far fa-check-circle text-success me-2 mt-2"></i>
																<span>{{toLearn}}.</span>
															</li>
														</ul>
													</div>
													
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							
							<div class="col-lg-4 col-md-12 col-12">
								<!-- Card -->
								<div class="card mb-3 mb-4">
									<div class="p-1">
										<div class="d-flex justify-content-center position-relative rounded py-10 border-white border rounded-3 bg-cover"
											:style="img_section_style(codelab?.bannerImage)"
										>
											<!-- <a class="popup-youtube icon-shape rounded-circle btn-play icon-xl text-decoration-none" data-bs-toggle="modal" data-bs-target="#newCatgory">
												<i class="fe fe-play"></i>
											</a> -->
											<!-- :href="details.videoUrl" -->
										</div>
									</div>

									<div
										class="modal fade"
										id="newCatgory"
									>
										<div class="modal-dialog modal-lg mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-fade mfp-ready" tabindex="-1" style="overflow: hidden auto;">
											<div class="mfp-container mfp-iframe-holder">
												<div class="mfp-content">
													<div class="mfp-iframe-scaler">
														<button title="Close (Esc)" type="button" class="mfp-close" data-bs-dismiss="modal">×</button>
														<iframe class="mfp-iframe" :src="details?.videoUrl" frameborder="0" allowfullscreen=""></iframe>
													</div>
												</div>
											</div>
										</div>
									</div>

									
									<!-- Card body -->
									<div class="card-body">
										<!-- Price single page -->
										<div class="mb-2 text-center" v-if="codelab?.passwords?.length === 0">
											<span class="badge" style="background-color: #19cb98; margin: 10px;">
												<i class="fe fe-unlock"></i>
											</span>
												<span class="fw-bold" style="color: #19cb98">Gratuit</span>
											<!-- <span class="text-dark fw-bold h2">Mood</span>
											<del class="fs-4 text-muted">$750</del> -->
										</div>
										<div class="mb-2 text-center" v-else>
											<span class="badge" style="background-color: #e53f3c; margin: 10px;">
												<i class="fe fe-lock"></i>
											</span>
												<span class="fw-bold" style="color: #e53f3c">Protégé</span>
											<!-- <span class="text-dark fw-bold h2">Mood</span>
											<del class="fs-4 text-muted">$750</del> -->
										</div>
										<div class="d-grid">
											<button v-if="Isloading" class="btn btn-primary" disabled>
												Ouverture <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
											</button>
											<a v-else class="btn btn-primary mb-2" @click.prevent="onSubmitAccess(codelab._id)">Ouvrir le lab</a>
										</div>
									</div>
								</div>
								<!-- Card -->
								<div class="card mb-4">
									<div>
										<!-- Card header -->
										<div class="card-header">
											<h4 class="mb-0">Ce qui est inclu </h4>
										</div>
										<ul class="list-group list-group-flush">
											<li class="list-group-item bg-transparent"><i class="fe fe-play-circle align-middle me-2 text-primary"></i>videos</li>
										
											<li class="list-group-item bg-transparent"><i class="fe fe-calendar align-middle me-2 text-info"></i>Articles
											</li>
											<li class="list-group-item bg-transparent border-bottom-0"><i class="fe fe-clock align-middle me-2 text-warning"></i>Accès à longue durée</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<!-- Card -->

						<!-- Page de details du lab -->


					</div>
				</div>
			</div>
		</div>
		<!-- Modal voir Password -->
	</div>
</template>

<script>
import { Codelabs } from "../../gql/queries/codelabs";
import { FindAllcodelabs } from "../../gql/queries/codelabs";
import { Lab } from "../../gql/queries/codelabs";
import { Validatecodelab } from "../../gql/mutations/validatecodelab"
import { Deletecodelab } from "../../gql/mutations/deletecodelab";
import { Updatecodelab } from '../../gql/mutations/codelabs';
import {removeFilterOnLab} from "../../gql/mutations/removeFilterOnlab";

export default {
	data() {
		return {
			Codelabs,
			CodelabsResponse: null,
			Deletecodelab,
			Validatecodelab,
			Lab,
			FiltresLabResponse: {},
			baseUrl: "https://api.labs.jkp-dev.com/",
			details : {},
			Updatecodelab,
			passwordLabLibre : "Passlibre",
			failAccess: false,
			filterdText:'',
			dataReady: false,
			Isloading: null,
		};
	},

	methods: {

		wait () {
            this.Isloading = true
        },
        waitEnd () {
            this.Isloading = false
        },
		
		getImagebanner(imageLink) {
			return imageLink;
		},

        img_section_style: function(imageLink){
            // var bgImg = this.codelab?.bannerImage
            return {
                // "background": 'url('+bgImg+')',
                "background-image": 'url('+imageLink+')'
            }
        },

		Updatelab(codelabID) {
			this.$router.push({
				name: "Updatelab",
				params: {
					id: codelabID,
				},
			});
			// console.log(codelabID)
		},

		Updatezip(codelabID) {
			this.$router.push({
				name: "Updateziplab",
				params: {
					id: codelabID,
				},
			});
			// console.log(codelabID)
		},

		async VoirFiltres(LabID) {
			const FiltresLab = await this.$apollo.query({
				query: Lab,
				variables: {
					id: LabID,
				},
			});
			// console.log(LabID);
			if (!FiltresLab.loading) {
				this.FiltresLabResponse = FiltresLab.data.codelab;
			}
			console.log(this.FiltresLabResponse._id);
		},

		async VoirDetails(LabID) {
			const response = await this.$apollo.query({
				query: Lab,
				variables: {
					id: LabID,
				},
			});
			if(response && !response.loading){

				const contenue_details =  response?.data?.codelab?.contenue_details
				this.details = JSON.parse(JSON.parse(JSON.stringify(contenue_details)))
				this.codelab = response?.data?.codelab
				// console.log(typeof this.details)
			}
		},

		remove(id) {
			this.$apollo.mutate({
				mutation: Deletecodelab,

				variables: {
					id: id,
				},
			});

			window.location.reload(false);
		},
		
		
		async removeFilter(FilterId) {
			const response = await this.$apollo.mutate({
				mutation: removeFilterOnLab,
				variables: {
					labId: this.FiltresLabResponse._id,
					filterId:FilterId
				},
				
			});

			if(response && response.data){
				window.location.reload(false);
			}
			
		},

		async onSubmitAccess(LabID) {
			this.wait()
			sessionStorage.setItem('IdLabAccess', LabID)
            try {
    
                const validate = await this.$apollo.mutate({
                    mutation: Validatecodelab,
                    variables: {
                        validateLabInput:{
                            id : LabID,
                            password: this.passwordLabLibre
                        }
                    }
                }).then(this.dataReady = true);
    
                if(!validate.loading){this.ValidateResponse = validate.data.validatecodelab}
    
                // console.log(this.ValidateResponse.verificationToken);
                // console.log('Moodlinklab' + this.LinkUrlLab + this.ValidateResponse.verificationToken);
                window.open(`${this.LinkUrlLab}=${this.ValidateResponse.verificationToken}`);
				this.waitEnd()
                this.$swal({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    icon: 'success',
                    timer:6000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', this.$swal.stopTimer)
                        toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                    },
                    title: 'Accès validé ! Ouverture du lab' 
                })
                // this.$router.push({ name: "Labs" });
            } catch (error) {
                error ? this.failAccess = true : false;
				this.waitEnd()
    
                if(error){
                    this.$swal({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        icon: 'error',
                        timer:3000,
                        title: 'Echec de connexion!',
                        text:'Quelque chose s`est mal passé. Vérifiez votre connection puis réessayez !',
                        
                    })
                }
    
                this.failAccess = false;
            }
        },
	},

	computed: {

        LinkUrlLab () {
			var codelabId = sessionStorage.getItem('IdLabAccess')
			console.log(codelabId);
            return `${this.baseUrl}gc/${codelabId}/?cf_lab`
        },

		// 	computedLabs(){
		// 		return this.CodelabsResponse.map((codelabs)=> (
		// 			{...this.codelabs,details: JSON.parse(codelabs.details)}
		// 		))
		// 	}

		filteredLabs(){
          if(this.filterdText){
            return this.CodelabsResponse.filter(codelabs => 
              codelabs.name.toLowerCase().includes(this.filterdText.toLowerCase())
            )
          }else{
            return this.CodelabsResponse
          }
        }
    },

	async mounted() {
		
		const response = await this.$apollo.query({
			query: FindAllcodelabs,
		});

		if (!response.loading) {
			this.CodelabsResponse = response.data.findAllcodelabs.map((findAllcodelabs) => ({
				...findAllcodelabs,
				details: JSON.parse(findAllcodelabs.details),
			}));
		}
	},
};
</script>
