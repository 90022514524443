import gql from "graphql-tag";

export const Validatecodelab = gql `mutation validatecodelab($validateLabInput: ValidateLabInput!) {
    validatecodelab(validateLabInput: $validateLabInput) {
      verificationToken
      lab{
        _id
      bannerImage
      details
      name
      path
      status
      }
    }
}
`