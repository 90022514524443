import gql from "graphql-tag";

export const RemoveFilter = gql`mutation removeFilter($_id: ID!) {
    removeFilter(_id: $_id) {
      _id
      value
      typefilter {
      _id
      title
      isActive
      description
      createdAt
    }
    }
  }
`