<template>
    <div class="container d-flex flex-column">
        <div class="row align-items-center justify-content-center g-0 min-vh-100">
            <div class="col-lg-5 col-md-8 py-8 py-xl-0">
                <!-- Card -->
                <div class="card shadow ">
                <!-- Card body -->
                    <div class="card-body p-6">
                        <div class="mb-4">
                        <a href=""><img src="assets/images/brand/logo/logo.png" class="mb-4" alt="" style="widht : auto; height : 40px;"></a>
                        <h1 class="mb-1 fw-bold">Se connecter</h1>
                        </div>
                        <!-- Form -->
                        <form>
                            <!-- Username -->
                        <div class="mb-3">
                            <label for="email" class="form-label">Email</label>
                            <input type="email" id="email" class="form-control" name="email" placeholder="Entrez votre adresse email"
                             v-model="input.email"
                            required>
                        </div>
                            <!-- Password -->
                        <div class="mb-5">
                            <label for="password" class="form-label">Mot de passe</label>
                            <input type="password" id="password" class="form-control" name="password" placeholder="Entrez votre mot de passe"
                            v-model="input.password"
                            required>
                        </div>
                        <div>
                            <!-- Button -->
                            <div class="d-grid">
                            <button v-if="Isloading" class="btn btn-primary" disabled>
                                Chargement <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </button>
                            <button v-else type="submit" href="" class="btn btn-primary" @click.prevent="login()"
                            >
                                Connexion
                            </button>
                        </div>
                        </div>
                        
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {Login} from "../gql/mutations/login"
export default {
    data() {
        return {
            Login,
            input: {},
            failAuth:false,
            dataReady: false,
			Isloading: null,
        }
    },

    methods: {

        wait () {
            this.Isloading = true
        },
        waitEnd () {
            this.Isloading = false
        },

        async login(){
            this.wait()
            try {
                const response = await this.$apollo.mutate({
                    mutation: Login,
                    variables : {
                        input: {
                            email: this.input.email,
                            password: this.input.password
                        },
                    }
                }).then(this.dataReady = true);

                if(response.data.login){
                    let data = response.data.login;
                    localStorage.setItem('token', `${data.access_token}`)
                    localStorage.setItem('user', JSON.stringify(data.userData))

                    this.$swal({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        icon: 'success',
                        timer:3000,
                        title: 'Bienvenue '+data.userData.fullname,  
                    })

                    this.$router.push("/admin");
                    setTimeout(function(){
                        window.location.reload(false);
                    }, 2500);
                }

            } catch (error) {
                error ? this.failAuth = true : false;
                this.waitEnd()

                if(error){
                this.$swal({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    icon: 'error',
                    timer:3000,
                    title: 'Echec de connexion!',
                    text:'Email ou mot de passe incorrect',
                    
                })
                }

                this.failAuth = false;
            }


        }
    },

    async mounted() {
        if(sessionStorage.getItem("VerifySession")){

            this.$swal({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                icon: 'info',
                timer:3000,
                title: 'Session expirée !',
                text:'Reconnectez vous pour y accéder !',
            })
            setTimeout(function(){
                sessionStorage.clear();
            }, 2000);
        }
    },
}
</script>