<template>
	<div class="container-fluid p-4">
		<div class="row">
			<!-- Page Header -->
			<div class="col-lg-12 col-md-12 col-12">
				<div
					class="
						border-bottom
						pb-4
						mb-4
						d-md-flex
						align-items-center
						justify-content-between
					"
				>
					<div class="mb-3 mb-md-0">
						<h1 class="mb-1 h2 fw-bold">
							Modifier l'abonnement
							<!-- <span class="fs-5">(210 Courses)</span> -->
						</h1>
					</div>
					<div>
						<a
							class="btn btn-secondary"
							@click="$router.push({ name: 'Abonnements' })"
						>
							Retour
						</a>
					</div>
				</div>
			</div>
		</div>
		<div class="row justify-content-md-center">
			<div class="col-lg-6 col-md-6 col-12">
				<!-- Card -->
				<div class="card mb-4">
					<!-- Card Header -->
					<div class="modal-body">
						<form>
                            <div class="mb-3">
								<label class="form-label" for="fname">
									Libellé
								</label>
								<input
									v-model="form.input.libelle"
									type="text"
									class="form-control"
									placeholder="Nom et prénoms"
									id="fname"
									required
								/>
							</div>
                            <div class="mb-3">
								<label class="form-label" for="description">
									Description
								</label>
								<input
									v-model="form.input.description"
									type="text"
									class="form-control"
									placeholder="description"
									id="description"
									required
								/>
							</div>
                            <div class="mb-3">
								<label class="form-label" for="price">
									Prix (En Fcfa)<span class="text-danger">*</span>
								</label>
								<input
									v-model="form.input.price"
									type="number"
									class="form-control"
									placeholder="Prix"
									id="price"
									required
								/>
							</div>
                            <div class="mb-6">
								<label class="form-label" for="validity">
									Validitée (En mois) <span class="text-danger">*</span>
								</label>
								<input
									v-model="form.input.validity"
									type="number"
									class="form-control"
									placeholder="validitée"
									id="validity"
									required
								/>
							</div>

							<div
								class="flex"
								style="display: flex; justify-content: end"
							>
								<button
									@click.prevent="updateAbonnement()"
									type="submit"
									class="btn btn-primary"
									style="margin-right: 5px"
								>
									Enregistrer
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Abonnement } from '../../gql/queries/abonnements'
import { Updateabonnement } from '../../gql/mutations/abonnements'

export default {
	data() {
		return {
			Abonnement,
            Updateabonnement,
            AbonnementResponse: null,
            abonnementID: this.$route.params.id,
            form: {
                input: {
                    description: null,
                    libelle: null,
                    price: null,
                    validity: null
                },
            },
		}
	},

	methods:{
		async updateAbonnement() {
            // const response2 = 
            await this.$apollo.mutate({
				mutation: Updateabonnement,
				variables: {
                    _id: this.abonnementID,
                    updateabonnementInput: {
                        description: this.form.input.description,
                        libelle: this.form.input.libelle,
                        price: this.form.input.price,
                        validity: this.form.input.validity
                    }
				},
			})
            this.$swal({
				toast: true,
				position: 'top-end',
				showConfirmButton: false,
				timer: 1200,
				timerProgressBar: true,
				didOpen: (toast) => {
					toast.addEventListener('mouseenter', this.$swal.stopTimer)
					toast.addEventListener('mouseleave', this.$swal.resumeTimer)
				},
				icon: 'success',
				title: 'Mis à jour avec succès'
			});
			
            this.$router.push({ name: "Abonnements" });
			// setTimeout(function(){
            //     window.location.reload(false);
            // }, 1000);
        }
	},
    
    async mounted() {
        const response = await this.$apollo.query({
            query: Abonnement,
            variables: {
                _id: this.abonnementID,
            },
        });
        
        if(!response.loading){this.AbonnementResponse = response.data.abonnement}

        this.form.input.description = this.AbonnementResponse.description;
        this.form.input.libelle = this.AbonnementResponse.libelle;
        this.form.input.price = this.AbonnementResponse.price;
        this.form.input.validity = this.AbonnementResponse.validity
    },
}

</script>
<style scoped>

.select-style {
    padding: 7px 1.25rem;
    border: 1px solid #e9e8ee;
    width: 100%;
    height: 40px;
    border-radius: 3px;
    background: #fff;
    position: relative;
}
.select-style select {
    padding: 5px 8px;
    width: 130%;
    border: none;
    box-shadow: none;
    background-color: transparent;
    background-image: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.select-style:after {
    top: 50%;
    left: 85%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(0, 0, 0, 0);
    border-top-color: #000000;
    border-width: 5px;
    margin-top: -2px;
    z-index: 100;
}
.select-style select:focus {
    outline: none;
}


</style>