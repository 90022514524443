<template>
    <div >
        <!-- navbar -->
        <nav class="navbar-default navbar navbar-expand-lg">
            <a id="nav-toggle" href="#">
                <i class="fe fe-menu"></i>
            </a>
            <!--Navbar nav -->
            <ul class="navbar-nav navbar-right-wrap ms-auto d-flex nav-top-wrap">
                <!-- List -->
                <li class="dropdown ms-2">
                    <a class="rounded-circle" href="#" role="button" id="dropdownUser" data-bs-toggle="dropdown" aria-expanded="false">
                        <div class="avatar avatar-md avatar-indicators avatar-online">
                            <img alt="avatar" src="../../public/assets/images/avatar/avatar-jkp.png" class="rounded-circle" />
                        </div>
                    </a>
                    <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownUser">
                        <div class="dropdown-item">
                            <div class="d-flex">
                                <div class="avatar avatar-md avatar-indicators avatar-online">
                                    <img alt="avatar" src="../../public/assets/images/avatar/avatar-jkp.png" class="rounded-circle" />
                                </div>
                                <div class="ms-3 lh-1">
                                    <h5 class="mb-1">JKP Admin</h5>
                                    <p class="mb-0 text-muted">admin@root.com</p>
                                </div>
                            </div>
                        </div>
                        <div class="dropdown-divider"></div>
                        <ul class="list-unstyled">
                            <li>
                                <a class="dropdown-item" @click="$router.push({ name: 'Users' })">
                                    <i class="fe fe-settings me-2"></i> Utilisateurs
                                </a>
                            </li>
                        </ul>
                        <div class="dropdown-divider"></div>
                        <ul class="list-unstyled">
                            <li>
                                <a class="dropdown-item" @click.prevent="LogOut()">
                                    <i class="fe fe-power me-2"></i> Déconnexion
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </nav>
    </div>
</template>
<script>
export default {
    data() {
        return {
            
        }
    },

    methods: {
        LogOut(){
            localStorage.clear();
            this.$router.push({ name: 'Login' })
        },

    }
}
</script>