import gql from 'graphql-tag';

export const createTypeFilter = gql `mutation createTypeFilter($createTypeFilterInput: CreateTypeFilterInput!) {
  createTypeFilter(createTypeFilterInput: $createTypeFilterInput) {
    _id
    title
    description
    createdAt
    description
    isActive
  }
}`

export const UpdateTypeFilter = gql`mutation updateTypeFilter($_id: ID!, $updateTypeFilterInput: UpdateTypeFilterInput!){
  updateTypeFilter(_id:$_id, updateTypeFilterInput:$updateTypeFilterInput){
    _id
    description
    title
    updatedAt
    isActive
  }
}`