import { defineStore } from 'pinia'

export const useCodelabStore = defineStore('codelab', {
    state: () => ({
        lab: ''
    }),
    getters: {
        
    },
    actions: {
        
    },
})