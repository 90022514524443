<template>
	<div class="container-fluid p-4">
		<div class="row">
			<!-- Page Header -->
			<div class="col-lg-12 col-md-12 col-12">
				<div
					class="
						border-bottom
						pb-4
						mb-4
						d-md-flex
						align-items-center
						justify-content-between
					"
				>
					<div class="mb-3 mb-md-0">
						<h1 class="mb-1 h2 fw-bold">
							Modifier le zip du lab {{ LabResponse?.name }}
							<!-- <span class="fs-5">(210 Courses)</span> -->
						</h1>
					</div>
					<div>
						<a
							class="btn btn-secondary"
							@click="$router.push({ name: 'Labs' })"
						>
							Retour
						</a>
					</div>
				</div>
			</div>
		</div>
		<div class="row justify-content-md-center">
			<!-- {{labDetailsInput}} -->
			<div class="col-lg-8 col-md-8 col-12">
				<!-- Card -->
				<div class="card mb-4">
					<!-- Card Header -->
					<div class="modal-body">
						<form>
							<div class="input-group mb-5 mt-3">
								<input
									@change="assignFileValue"
									type="file"
									class="form-control"
									id="inputGroupFile02"
								/>
								<label class="input-group-text" for="inputGroupFile02"
									>Charger le zip</label
								>
							</div>

							<div
								class="flex"
								style="display: flex; justify-content: end"
							>
								<button
									@click.prevent="updateCodelab()"
									type="submit"
									class="btn btn-primary"
									style="margin-right: 5px"
								>
									Enregistrer
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Codelab } from "../../gql/queries/codelabs";
import { Updatecodelab } from "../../gql/mutations/codelabs";
import {ChangeLabFile} from "../../gql/mutations/changeLabFile"

export default {
	data() {
		return {
			Codelab,
			codelabID: this.$route.params.id,
			PassTableau: [],
			maxSize:5000,
			ChangeLabFile,
			file:{},
			form: {
				input: {
					name: null,
					details: {
						description: "",
						level: "",
						time: "",
					},
					password: null,
					path: null,
					status: true,
				},
			},
			Updatecodelab,
		};
	},

	methods: {
		async assignFileValue(event) {
			this.file = event.target.files[0];
		},
		async updateCodelab() {
			if (this.file) {
				let isInSizeRange =
					this.file.size / 1000 <= this.maxSize ? true : false;

				if (!isInSizeRange) return alert("Le fichier est trop lourd");
			}
			// const response2 =
			await this.$apollo.mutate({
				mutation: ChangeLabFile,
				variables: {
					labId: this.codelabID,
					file:this.file
					// updateLabInput: {
					// 	path: this.form.input.path,
					// },
				},
			});
			this.$swal({
				toast: true,
				position: "top-end",
				showConfirmButton: false,
				timer: 1200,
				timerProgressBar: true,
				didOpen: (toast) => {
					toast.addEventListener("mouseenter", this.$swal.stopTimer);
					toast.addEventListener("mouseleave", this.$swal.resumeTimer);
				},
				icon: "success",
				title: "Mis à jour avec succès",
			});

			this.$router.push({ name: "Labs" });
			setTimeout(function () {
				window.location.reload(false);
			}, 1000);


			// console.log(response2);
		},
	},

	async mounted() {
		const response = await this.$apollo.query({
			query: Codelab,
			variables: {
				id: this.codelabID,
			},
		});

		if (!response.loading) {
			this.LabResponse = response.data.codelab;
		}
	},
};
</script>