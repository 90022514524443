<template>
    <div class="container-fluid p-4">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
                <div class="border-bottom pb-4 mb-4 d-md-flex justify-content-between align-items-center">
                    <div class="mb-3 mb-md-0">
                        <h1 class="mb-0 h2 fw-bold">Dashboard</h1>
                    </div>
                    <div class="d-flex">
                        <a 
                            onclick="window.location.reload(false)"
                            class="btn btn-primary"
                        >
                        Actualiser
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="Isloading" class="card text-center">
            <div id="circle5" style="margin-top: 5em;"></div>
        </div>
        <div v-else class="row">
            <div class="col-xl-3 col-lg-6 col-md-12 col-12">
                <!-- Card -->
                <div class="card mb-4">
                    <!-- Card body -->
                    <div class="card-body">
                        <span
                            class="fs-6 text-uppercase fw-semi-bold"
                            >Labs</span
                        >
                        <div
                            class="mt-2 d-flex justify-content-between align-items-center"
                        >
                            <div class="">
                                <h2 class="h1 fw-bold mb-1">{{ CodelabsResponse.length }}</h2>
                                <span>Nombre de labs</span>
                            </div>
                            <div>
                                <span
                                    class="bg-light-primary icon-shape icon-xl rounded-3 text-dark-primary"
                                >
                                    <i class="mdi mdi-text-box-multiple mdi-24px"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-6 col-md-12 col-12">
                <div class="card mb-4">
                    <div class="card-body">
                        <span
                            class="fs-6 text-uppercase fw-semi-bold"
                            >Étudiants abonnés</span
                        >
                        <div
                            class="mt-2 d-flex justify-content-between align-items-center"
                        >
                            <div class="">
                                <h2 class="h1 fw-bold mb-1"> {{ ActiveSubscriptionResponse.length }} </h2>
                                <span>Étudiants avec abonnement actif</span>
                            </div>
                            <div>
                                <span
                                    class="bg-light-success icon-shape icon-xl rounded-3 text-dark-success"
                                >
                                    <i class="mdi mdi-cash mdi-24px"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-6 col-md-12 col-12">
                <div class="card mb-4">
                    <div class="card-body">
                        <span
                            class="fs-6 text-uppercase fw-semi-bold"
                            >Étudiants</span
                        >
                        <div
                            class="mt-2 d-flex justify-content-between align-items-center"
                        >
                            <div class="">
                                <h2 class="h1 fw-bold mb-1">{{ StudentsResponse.length }}</h2>
                                <span>Nombre d'étudiants</span>
                            </div>
                            <div>
                                <span
                                    class="bg-light-warning icon-shape icon-xl rounded-3 text-dark-warning"
                                >
                                    <i class="mdi mdi-school mdi-24px"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-6 col-md-12 col-12">
                <div class="card mb-4">
                    <div class="card-body">
                        <span
                            class="fs-6 text-uppercase fw-semi-bold"
                            >Utilisateurs</span
                        >
                        <div
                            class="mt-2 d-flex justify-content-between align-items-center"
                        >
                            <div class="">
                                <h2 class="h1 fw-bold mb-1">{{ UsersResponse.length }}</h2>
                                <span>Nombre d'utilisateurs admins</span>
                            </div>
                            <div>
                                <span
                                    class="bg-light-info icon-shape icon-xl rounded-3 text-dark-info"
                                >
                                    <i class="mdi mdi-account-multiple mdi-24px"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Codelabs } from "../gql/queries/codelabs"
import { FindFilters } from "../gql/queries/typefilters"
import { Filtres } from "../gql/queries/filtres"
import { Users } from '../gql/queries/users'
import {Students } from '../gql/queries/users'
import {StudentsAsActiveSubscription} from '../gql/queries/users'

export default {
	data() {
		return {
			CodelabsResponse: [],
			details : {},
            // codelabs: [],
            FindFiltersResponse: [],
            FiltresResponse: [],
            UsersResponse : [],
            StudentsResponse : [],
            ActiveSubscriptionResponse : [],
            Isloading: null,
		};
	},

    methods: {
        wait () {
            // setTimeout (() => {
                this.Isloading = true
            // }, 500)
        }
    },

    created() {
        this.Isloading = true
    },

	async mounted(){
        this.wait()
		const [response4, response3, response2, response, StudentsQuery, StudentsAsActiveSubscriptionQuery] = await Promise.all([
            await this.$apollo.query({
                query:Users
            }),
            await this.$apollo.query({
                query: Filtres,
            }),
            await this.$apollo.query({
                query: FindFilters,
            }),
            await this.$apollo.query({
                query: Codelabs,
            }),
            await this.$apollo.query({
                query: Students,
            }),
            await this.$apollo.query({
                query: StudentsAsActiveSubscription,
            }),

        ]).then(this.Isloading = false);
		// console.log(response)
        if(!response4.loading){this.UsersResponse = response4.data.admins}

        if(!response3.loading){this.FiltresResponse = response3.data.findFilters}

		if(!response2.loading){this.FindFiltersResponse = response2.data.typeFilters}

        
		if(!response.loading){
            this.CodelabsResponse = response.data.codelabs.map((codelabs)=> ({...codelabs,details: JSON.parse(codelabs.details)}))
        }

        if(!StudentsQuery.loading){this.StudentsResponse = StudentsQuery.data.users}

        if(!StudentsAsActiveSubscriptionQuery.loading){this.ActiveSubscriptionResponse = StudentsAsActiveSubscriptionQuery.data.userWithActiveSubscriptions}
	},
};
</script>
<style scoped>

/**===== Loader circle5 =====*/
#circle5 {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 50px;
  width: 50px;
  margin: -25px 0 0 -25px;
  border: 4px rgb(129 30 30 / 0%) solid;
  border-top: 4px #ef4033 solid;
  border-right: 4px #f9ba30 solid;
  border-bottom: 4px #37abe3 solid;
  border-radius: 50%;
  -webkit-animation: spin5 1s infinite linear;
          animation: spin5 1s infinite linear;
}

@-webkit-keyframes spin5 {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
@keyframes spin5 {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
/** END Loader of circle5 */

</style>