<template>
	<div class="container-fluid p-4">
		<div class="row">
			<!-- Page Header -->
			<div class="col-lg-12 col-md-12 col-12">
				<div
					class="
						border-bottom
						pb-4
						mb-4
						d-md-flex
						align-items-center
						justify-content-between
					"
				>
					<div class="mb-3 mb-md-0">
						<h1 class="mb-1 h2 fw-bold">Gestion des souscriptions</h1>
					</div>
					<!-- <div>
						<a class="btn btn-primary" @click="$router.push({ name: 'AddAbonnement' })">
							Ajouter une souscription
						</a>
					</div> -->
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-12 col-md-12 col-12">
				<!-- Card -->
				<div class="card mb-4">
					<!-- Card header -->
					<div class="card-header border-bottom-0">
						<span class="mb-1 h4 fw-bold">Liste des souscriptions</span>
						<!-- <form class="d-flex align-items-center">
							<span class="position-absolute ps-3 search-icon">
								<i class="fe fe-search"></i>
							</span>
							<input
								type="search"
								class="form-control ps-6"
								placeholder="Rechercher une catégorie de lab"
							/>
						</form> -->
					</div>
					<!-- Table -->
					<div class="table-responsive border-0 overflow-y-hidden">
						<table class="table mb-0 text-nowrap">
							<thead class="table-light">
								<tr>
									<!-- <th class="border-0 font-size-inherit">
										<div
											class="form-check"
											style="visibility: hidden"
										>
											<input
												type="checkbox"
												class="form-check-input"
												id="checkAll"
											/>
											<label
												class="form-check-label"
												for="checkAll"
											></label>
										</div>
									</th> -->
									<th class="border-0">N°</th>
									<th class="border-0">Client</th>
									<th class="border-0">Email</th>
									<th class="border-0">Dernière co°</th>
									<th class="border-0">Abonnement</th>
									<th class="border-0">Durée</th>
									<th class="border-0">Prix</th>
									<th class="border-0">Validité</th>
									<th class="border-0">Status</th>
									<th class="border-0">Détails</th>
									<th class="border-0">
										<i class="fe fe-more-vertical"></i>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(subscription, index) in SubscriptionsResponse" :key="subscription._id">
									<td class="align-middle">
										<a class="text-inherit">
											<h5 class="mb-0 text-primary-hover">{{ index + 1 }}</h5>
										</a>
									</td>
									<td class="align-middle">
										<a class="text-inherit">
											<h5 class="mb-0 text-primary-hover">{{ subscription.users.name }}</h5>
										</a>
									</td>
									<td class="align-middle">
										<a class="text-inherit">
											<h5 class="mb-0 text-primary-hover">{{ subscription.users.email }}</h5>
										</a>
									</td>
									<td class="align-middle">
										<a class="text-inherit">
											<h5 class="mb-0 text-primary-hover">{{ subscription.users.lastConnexion }}</h5>
										</a>
									</td>
									<td class="align-middle"> <h5 class="mb-0">{{ subscription.abonnement.libelle }}</h5> </td>
									<td class="align-middle">{{ subscription.abonnement.validity }}</td>
									<td class="align-middle">{{ subscription.abonnement.price.toLocaleString() }}</td>
									<td class="align-middle">Du <strong>{{ subscription.start }}</strong> Au <strong>{{ subscription.end }}</strong></td>
									<td class="align-middle">
										<span v-if="subscription?.status === 1" class="badge bg-success">Active</span>
										<span v-if="subscription?.status === 0" class="badge bg-warning">En attente de paiement</span>
										<span v-if="subscription?.status === -1" class="badge bg-danger">Paiement échoué</span>
										<span v-if="subscription?.status === -2" class="badge bg-warning">Expirée</span>
										<span v-if="subscription?.status === -3" class="badge bg-danger">Incomplet</span>
									</td>
									<td class="align-middle">
										<a
											@click.prevent="VoirDetails(subscription._id, index)"
											class="btn btn-success btn-xs"
											data-bs-toggle="modal"
											data-bs-target="#voirDetails"
										>
											<i class="fe fe-eye"></i>
										</a>
									</td>
									<td class="align-middle border-top-0">
										<span class="dropdown dropstart">
											<a
												class="text-decoration-none text-muted"
												role="button"
												id="courseDropdown1"
												data-bs-toggle="dropdown"
												data-bs-offset="-20,20"
												aria-expanded="false"
											>
												<i class="fe fe-more-vertical"></i>
											</a>
											<span
												class="dropdown-menu"
												aria-labelledby="courseDropdown1"
											>
												<span class="dropdown-header">Actions</span>
												<!-- <a class="dropdown-item"
													@click="Updateabonnement(abonnement._id)"
													><i
														class="fe fe-edit dropdown-item-icon"
													></i
													>Modifier</a
												> -->
												<a class="dropdown-item" style="color : red"
													data-bs-toggle="modal"
													:data-bs-target="'#supprimer' + subscription._id"
												>
												<i class="fe fe-trash dropdown-item-icon" style="color : red"></i> Supprimer</a>
											</span>
										</span>
									</td>

									<!-- Modal suppression -->
									<div
										class="modal fade"
										:id="'supprimer' + subscription._id"
										tabindex="-1"
										role="dialog"
										aria-labelledby="newLabLabel"
										aria-hidden="true"
									>
										<div class="modal-dialog">
											<div class="modal-content">
												<div class="modal-body text-center">
													<h3 class="modal-title" id="exampleModalCenterTitle">Êtes vous sûr de vouloir supprimer ?</h3>
													<h4 class="modal-title">Cette action est irréversible !</h4>
													<h5 class="modal-title">L'utilisateur n'aura plus d'abonnement actif</h5>
													<h5 class="modal-title">et cela pourrait créer des erreurs</h5>
												</div>
												<div class="modal-footer flex"
													style="display: flex; justify-content: space-between; "
												>
													<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
													<button type="button" class="btn btn-danger" @click="remove(subscription._id)" data-bs-dismiss="modal">
														Supprimer
													</button>
												</div>
											</div>
										</div>
									</div>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>

		<!-- Modal Voir Details -->
		<div
			class="modal fade"
			id="voirDetails"
			tabindex="-1"
			role="dialog"
			aria-labelledby="VoirLabel"
			aria-hidden="true"
		>
			<div class="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title mb-0" id="VoirLabel">
							Détais de la souscription N° <span class="fw-bold text-center" style="color:red"> {{DetailsIndex +1}} </span>
						</h4>
						<button
							type="button"
							class="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
						></button>
					</div>
					<div class="modal-body">
						<div class="fs-4">


							<!-- ===== Modal Voir filtre ======= -->
							<div class="card-body border-bottom">
								<div class="d-flex justify-content-between mb-6">
									<div>
									<!-- Img -->
									<img src="assets/images/brand/logo/logo.png" alt="" style="width: 100px">
									<h4 class="mb-0">JKP-LABS</h4>
									<small>SOUSCRIPTION #ID : {{SubscriptionResponse?._id}}</small>
									</div>
									<div>
									</div>
								</div>
								<!-- Row -->
								<div class="row">
									<div class="col-md-8 col-12">
									<span class="fs-6">Informations du client</span>
									<h4 class="mb-2"> <span class="fs-5">Nom :</span> {{SubscriptionResponse?.users?.name}}</h4>
									<p>
										Prenoms : {{SubscriptionResponse?.users?.lastname}} <br>
										Email : {{SubscriptionResponse?.users?.email}} <br>
										Téléphone : {{SubscriptionResponse?.users?.phone_number}} <br>
										Date de naissance : {{SubscriptionResponse?.users?.birth}}
									</p>
									</div>
									<div class="col-md-4 col-12">
									<p>
										Créé le : {{SubscriptionResponse?.users?.createdAt}} <br>
										Status compte :
										<span v-if="SubscriptionResponse?.users?.mailWasConfirmed === true" class="badge bg-success">Vérifié</span>
										<span v-else class="badge bg-warning">En attente de confirmation par mail</span>
									</p>
									<span class="fs-6">Dernière connexion</span>
									<h6 class="mb-0">{{SubscriptionResponse?.users?.lastConnexion}}</h6>
									</div>
								</div>
								<!-- Row -->
								<!-- <div class="row mb-5">
									<div class="col-8">
									<span class="fs-6">INVOICED ID</span>
									<h6 class="mb-0">#1001</h6>
									</div>
									<div class="col-4">
									<span class="fs-6">Dernière connexion</span>
									<h6 class="mb-0">{{SubscriptionResponse?.users?.lastConnexion}}</h6>
									</div>
								</div> -->
								<!-- Table -->
								<div class="table-responsive mb-12">
									<table class="table mb-0 text-nowrap table-borderless">
									<thead class="table-light">
										<tr>
										<th scope="col">Abonnement</th>
										<th scope="col">Durée</th>
										<th scope="col">Prix</th>
										<th scope="col">Validité</th>
										<th scope="col">Status</th>
										</tr>
									</thead>
									<tbody>
										<tr class="text-dark">
										<td style="width: 250px;">{{ SubscriptionResponse?.abonnement?.libelle }}</td>
										<td>{{ SubscriptionResponse?.abonnement?.validity }}</td>
										<td><strong>{{ SubscriptionResponse?.abonnement?.price.toLocaleString() }}</strong></td>
										<td><strong>{{ SubscriptionResponse?.start }}</strong> Au <strong>{{ SubscriptionResponse?.end }}</strong> </td>
										<td>
											<span v-if="SubscriptionResponse?.status === 1" class="badge bg-success">Actif</span>
											<span v-if="SubscriptionResponse?.status === 0" class="badge bg-warning">En attente de paiement</span>
											<span v-if="SubscriptionResponse?.status === -1" class="badge bg-danger">Paiement échoué</span>
											<span v-if="SubscriptionResponse?.status === -2" class="badge bg-warning">Expiré</span>
											<span v-if="SubscriptionResponse?.status === -3" class="badge bg-danger">Incomplet</span>
										</td>
										</tr>
									</tbody>
									<tfoot>
										<tr class="text-dark">
										<td colspan="5">Description : {{ SubscriptionResponse?.abonnement?.description }}</td>
										</tr>
									</tfoot>
									</table>
								</div>
							</div>
							<!-- ============ -->

							<div
								class="flex mt-5"
								style="display: flex; justify-content: end"
							>
								<button
									type="button"
									class="btn btn-outline-white"
									data-bs-dismiss="modal"
								>
									Fermer
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- Modal voir Filtres -->
	</div>
</template>
<script>
import { Subscriptions } from '../../gql/queries/subscriptions'
import { Subscription } from '../../gql/queries/subscriptions'
import { RemoveSubscription } from '../../gql/mutations/subscriptions'

export default {
	data() {
		return {
            Subscriptions,
			SubscriptionsResponse : null,
			Subscription,
			SubscriptionResponse : {},
			RemoveSubscription,
			DetailsIndex : null
		}
	},
	
	methods: {

		async VoirDetails(SubscriptionID, index) {
			const VoirDetailsResponse = await this.$apollo.query({
				query: Subscription,

				variables: {
					id: SubscriptionID,
				}
			})
			if (!VoirDetailsResponse.loading) {this.SubscriptionResponse = VoirDetailsResponse.data.subscription}
			this.DetailsIndex = index
		},


		async remove(SubscriptionID){
            const deletedAb = await this.$apollo.mutate({
				mutation: RemoveSubscription,

				variables: {
					id: SubscriptionID,
				}
			})
            if(deletedAb && deletedAb.data){

                this.$swal({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 1200,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', this.$swal.stopTimer)
                        toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                    },
                    icon: 'success',
                    title: 'Supprimé avec succès'
                });
                
                setTimeout(function(){
                    window.location.reload(false);
                }, 1000);
			}

		},
	},

	async mounted() {
		const response = await this.$apollo.query({
			query:Subscriptions
		})

		if(!response.loading){this.SubscriptionsResponse = response.data.subscriptions}
		
	},
}
</script>