<template>
	<div class="container-fluid p-4">
		<div class="row">
			<!-- Page Header -->
			<div class="col-lg-12 col-md-12 col-12">
				<div
					class="
						border-bottom
						pb-4
						mb-4
						d-md-flex
						align-items-center
						justify-content-between
					"
				>
					<div class="mb-3 mb-md-0">
						<h1 class="mb-1 h2 fw-bold">
							Attribuer un filtre à un lab
							<!-- <span class="fs-5">(210 Courses)</span> -->
						</h1>
					</div>
					<div>
						<a
							class="btn btn-secondary"
							@click="$router.push({ name: 'Labs' })"
						>
							Retour
						</a>
					</div>
				</div>
			</div>
		</div>
		<div class="row justify-content-md-center">
			<div class="col-lg-8 col-md-8 col-12">
				<!-- Card -->
				<div class="card mb-4">
					<!-- Card Header -->
					<div class="modal-body">
						<form>
							<div class="mb-3">
								<label class="form-label" for="title">
									Codelab <span class="text-danger">*</span>
								</label>
								<select 
									v-model="addNewFilterInput._id"
									class="select-style" data-width="100%"
									required
								>
									<option>Selectionner un lab</option>
									<option
										v-for="codelab in CodelabsResponse" :key="codelab._id"
										:value="codelab._id"
									> {{ codelab.name }}
									</option>

								</select>
								<small> Selectionner un lab </small>
							</div>

							<div class="mb-3">
								<label class="form-label" >
									Filtre <span class="text-danger">*</span>
								</label>
								<select 
									v-model="addNewFilterInput.filterId"
									class="select-style" data-width="100%"
									required
								>
									<option>Selectionner un filtre</option>
									<option
										v-for="findFilter in FiltresResponse2" :key="findFilter._id"
										:value="findFilter._id"
									> {{ findFilter.value }}
									</option>

								</select>
								<small> Selectionner un filtre </small>
							</div>

							<!-- <div class="mb-3">
								<label class="form-label" for="title">
									Mot de passe <span class="text-danger">*</span>
								</label>
								<input
									v-model="createPasswordInput.pass"
									type="text"
									class="form-control"
									placeholder="Mot de passe"
									id="title"
									required
								/>
								<small>Entrer le mot de passe du lab</small>
							</div>

							<div class="mb-6">
								<label class="form-label" for="title">
									Description
								</label>
								<input
									v-model="createPasswordInput.description"
									type="text"
									class="form-control"
									placeholder="Description"
									id="title"
									required
								/>
								<small>Entrer le mot de passe du lab</small>
							</div> -->

							<div
								class="flex"
								style="display: flex; justify-content: end"
							>
								<button
									@click.prevent="addPasswword()"
									type="submit"
									class="btn btn-primary"
									style="margin-right: 5px"
								>
									Enregistrer
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Codelabs } from "../../gql/queries/codelabs"
import { Filtres } from "../../gql/queries/filtres"
import { AddNewFilter } from "../../gql/mutations/addNewFilter"

export default {
	data() {
		return {
			CodelabsResponse: null,
			FiltresResponse2: null,
            Filtres,
			AddNewFilter,
			addNewFilterInput: {}
		}
	},

	methods:{
		async addPasswword(){
			await this.$apollo.mutate({
				mutation: AddNewFilter,
				variables: {
					_id : this.addNewFilterInput._id,
                    filterId: this.addNewFilterInput.filterId
				}
			})
			this.$swal({
				toast: true,
				position: 'top-end',
				showConfirmButton: false,
				timer: 1200,
				timerProgressBar: true,
				didOpen: (toast) => {
					toast.addEventListener('mouseenter', this.$swal.stopTimer)
					toast.addEventListener('mouseleave', this.$swal.resumeTimer)
				},
				icon: 'success',
				title: 'Ajouté avec succès'
			});
			
            this.$router.push({ name: "Labs" });
			
            setTimeout(function(){
                window.location.reload(false);
            }, 1000);
		},
	},

	async mounted() {
		const response = await this.$apollo.query({
			query:Codelabs
		})

		if(!response.loading){this.CodelabsResponse = response.data.codelabs }

        
		const response2 = await this.$apollo.query({
			query:Filtres
		})

		if(!response2.loading){this.FiltresResponse2 = response2.data.findFilters }

	},
}

</script>
<style scoped>

.select-style {
    padding: 7px 1.25rem;
    border: 1px solid #e9e8ee;
    width: 100%;
    height: 40px;
    border-radius: 3px;
    background: #fff;
    position: relative;
}
.select-style select {
    padding: 5px 8px;
    width: 130%;
    border: none;
    box-shadow: none;
    background-color: transparent;
    background-image: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.select-style:after {
    top: 50%;
    left: 85%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(0, 0, 0, 0);
    border-top-color: #000000;
    border-width: 5px;
    margin-top: -2px;
    z-index: 100;
}
.select-style select:focus {
    outline: none;
}


</style>