import gql from "graphql-tag";

export const CreateFilter = gql`mutation createFilter($createFilterInput: CreateFilterInput!) {
  createFilter(createFilterInput: $createFilterInput) {
    _id
    value
    typefilter{description}
    labs{_id name}
    createdAt
  }
}
`

export const UpdateFilter = gql`mutation updateFilter($_id: ID!, $updateFilterInput: UpdateFilterInput!) {
  updateFilter(_id: $_id, updateFilterInput: $updateFilterInput) {
    _id
    createdAt
    value
    typefilter{_id title description}
    labs{_id name}
  }
}
`