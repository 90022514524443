<template>
	<div class="container-fluid p-4">
		<div class="row">
			<!-- Page Header -->
			<div class="col-lg-12 col-md-12 col-12">
				<div
					class="
						border-bottom
						pb-4
						mb-4
						d-md-flex
						align-items-center
						justify-content-between
					"
				>
					<div class="mb-3 mb-md-0">
						<h1 class="mb-1 h2 fw-bold">
							Ajouter un nouveau lab
							<!-- <span class="fs-5">(210 Courses)</span> -->
						</h1>
					</div>
					<div>
						<a
							class="btn btn-secondary"
							@click="$router.push({ name: 'Labs' })"
						>
							Retour
						</a>
					</div>
				</div>
			</div>
		</div>
		<div class="row justify-content-md-center">
			<!-- {{labDetailsInput}} -->
			<div class="col-lg-8 col-md-8 col-12">
				<!-- Card -->
				<div class="card mb-4">
					<!-- Card Header -->
					<div class="modal-body">
						<form>
							<div
								class="custom-file-container"
								data-upload-id="courseCoverImg"
								id="courseCoverImg"
							>
								<label class="form-label"
									>Image de couverture
									<a
										href="javascript:void(0)"
										class="custom-file-container__image-clear"
										title="Clear Image"
									></a
								></label>
								<label class="custom-file-container__custom-file">
									<!-- @change="assignbannerImageValue" -->
									<input
										type="file"
										class="
											custom-file-container__custom-file__custom-file-input
										"
										@change="assignbannerImageValue"
										accept="image/*"
									/>
									<!-- <input
										type="hidden"
										name="MAX_FILE_SIZE"
										value="10485760"
									/> -->
									<span
										class="
											custom-file-container__custom-file__custom-file-control
										"
									></span>
								</label>
								<small class="mt-3 d-block"
									>Charger l'image de couverture du lab</small
								>
								<div class="custom-file-container__image-preview"></div>
							</div>

							<div class="mb-3">
								<label class="form-label" for="title">
									Nom du lab <span class="text-danger">*</span>
								</label>
								<input
									v-model="createLabInput.name"
									type="text"
									class="form-control"
									placeholder="Nom du lab"
									required
								/>
								<small> Entrer le nom du lab </small>
							</div>

							<div class="mb-3">
								<label class="form-label" for="title">
									Description <span class="text-danger">*</span>
								</label>
								<input
									v-model="labDetailsInput.description"
									type="text"
									class="form-control"
									placeholder="Description"
									required
								/>
								<small> Entrer la description du cours </small>
							</div>

							<div class="mb-3">
								<label class="form-label" for="title">
									Niveau <span class="text-danger">*</span>
								</label>
								<select
									v-model="labDetailsInput.level"
									class="select-style"
									data-width="100%"
									required
								>
									<option value="">Selectionner un niveau</option>
									<option value="BEGINNER">Débutant</option>
									<option value="INTERMEDIATE">Intermmédiaire</option>
									<option value="ADVANCED">Avancé</option>
								</select>
								<small> Selectionner un cours </small>
							</div>

							<div class="mb-4">
								<label class="form-label" for="title">
									Temps <span class="text-danger">*</span>
								</label>
								<input
									v-model="labDetailsInput.time"
									type="text"
									class="form-control"
									placeholder="Durée (Ex : 1h30)"
									required
								/>
								<small> Entrer la durée du cours </small>
							</div>

							<div class="input-group mb-5">
								<input
									@change="assignFileValue"
									type="file"
									class="form-control"
									id="inputGroupFile02"
								/>
								<label class="input-group-text" for="inputGroupFile02"
									>Charger le zip</label
								>
							</div>

							<div
								class="flex"
								style="display: flex; justify-content: end"
							>
								<button v-if="Isloading" class="btn btn-primary" disabled>
									Chargement <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
								</button>
								<button
									v-else
									@click.prevent="addCodelab()"
									type="submit"
									class="btn btn-primary"
									style="margin-right: 5px"
								>
									Enregistrer
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// import { Categories } from "../../gql/queries/categories"
import { Createcodelab } from "../../gql/mutations/codelabs";
export default {
	data() {
		return {
			// CategoriesResponse: null,
			Createcodelab,
			createLabInput: {
				status: false,
			},
			labDetailsInput: {},
			bannerImage: null,
			file: null,
			maxSize: 10000,
			dataReady: false,
			Isloading: null,
		};
	},

	methods: {

		wait () {
            this.Isloading = true
        },
        waitEnd () {
            this.Isloading = false
        },

		async addCodelab() {
			this.wait()
			//La limite du poids des fichiers chargés

			if (this.file) {
				let isInSizeRange =
					this.file.size / 1000 <= this.maxSize ? true : false;

				if (!isInSizeRange) return alert("Le fichier est trop lourd");
			}

			if (this.bannerImage) {
				let isInSizeRange =
					this.bannerImage.size / 1000 <= this.maxSize ? true : false;

				if (!isInSizeRange) return alert("L'image est trop lourde");
			}

			delete this.bannerImage.token;

			// La mutation et ses variables

			const payload = { file: this.file, bannerImage: this.bannerImage };

			const response2 = await this.$apollo.mutate({
				mutation: Createcodelab,
				variables: {
					createLabInput: { ...this.createLabInput },
					labDetailsInput: this.labDetailsInput,
					bannerImage: payload.bannerImage,
					file: payload.file,
				},
				context: {
					hasUpload: true,
				},
			});
			this.waitEnd()

			this.$router.push({ name: "Labs" });

			console.log(response2);
		},

		async assignFileValue(event) {
			this.file = event.target.files[0];
		},

		async assignbannerImageValue(event) {
			this.bannerImage = event.target.files[0];
		},
	},

	// async mounted(){
	// 	const response = await this.$apollo.query({
	// 		query:Categories
	// 	})
	// 	console.log(response)

	// 	if(!response.loading){this.CategoriesResponse = response.data.categories}
	// },
};
</script>

<style scoped>
.select-style {
	padding: 7px 1.25rem;
	border: 1px solid #e9e8ee;
	width: 100%;
	height: 40px;
	border-radius: 3px;
	background: #fff;
	position: relative;
}
.select-style select {
	padding: 5px 8px;
	width: 130%;
	border: none;
	box-shadow: none;
	background-color: transparent;
	background-image: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
.select-style:after {
	top: 50%;
	left: 85%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(0, 0, 0, 0);
	border-top-color: #000000;
	border-width: 5px;
	margin-top: -2px;
	z-index: 100;
}
.select-style select:focus {
	outline: none;
}
</style>