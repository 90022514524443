import gql from 'graphql-tag'

export const Filtres = gql`query findFilters {
  findFilters {
    _id
    typefilter {
      _id
      title
      isActive
      description
      createdAt
    }
    value
    labs {
      _id
      name
    }
    createdAt
  }
}`

export const Filtre = gql`query findFilter($_id: ID!) {
  findFilter(_id: $_id) {
    _id
    typefilter {
      _id
      title
      isActive
      description
      createdAt
    }
    createdAt
    value
    labs {
      _id
      name
      bannerImage
    }
  }
}
`