import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'

import { createApolloProvider } from '@vue/apollo-option';
import apolloClient from './gql/client';

import router from './router';

//SweetAlert2
// import './index.css'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { Auth } from './components/route';

router.beforeEach(Auth.authIsRequire);

const options = {
    confirmButtonColor: '#e53f3c',
};

const apolloProvider = createApolloProvider({
    defaultClient: apolloClient,
})

const pinia = createPinia()
const app = createApp (App)

app.use(pinia)
app.use(VueSweetalert2, options);
app.use(apolloProvider);
app.use(router);
app.mount('#app')
