<template>
	<div class="container-fluid p-4">
		<div class="row">
			<!-- Page Header -->
			<div class="col-lg-12 col-md-12 col-12">
				<div
					class="
						border-bottom
						pb-4
						mb-4
						d-md-flex
						align-items-center
						justify-content-between
					"
				>
					<div class="mb-3 mb-md-0">
						<h1 class="mb-1 h2 fw-bold">
							Ajouter un nouveau filtre
							<!-- <span class="fs-5">(210 Courses)</span> -->
						</h1>
					</div>
					<div>
						<a
							class="btn btn-secondary"
							@click="$router.push({ name: 'Filtres' })"
						>
							Retour
						</a>
					</div>
				</div>
			</div>
		</div>
		<div class="row justify-content-md-center">
			<div class="col-lg-8 col-md-8 col-12">
				<!-- Card -->
				<div class="card mb-4">
					<!-- Card Header -->
					<div class="modal-body">
						<form>
							<div class="mb-3">
								<label class="form-label" for="title">
									Type de filtre <span class="text-danger">*</span>
								</label>
								<select 
									v-model="createFilterInput.typeFilter"
									class="select-style" data-width="100%"
									required
								>
									<option>Selectionner un type de filtre</option>
									<option v-for="typeFilter in FindFiltersResponse" :key="typeFilter._id" :value="typeFilter._id"> 
										{{typeFilter.title}} 
									</option>

								</select>
								<small> Selectionner un type de filtre </small>
							</div>

							<div class="mb-6">
								<label class="form-label" for="title">
									Nom du filtre
								</label>
								<input
									v-model="createFilterInput.value"
									type="text"
									class="form-control"
									placeholder="Description"
									id="title"
									required
								/>
								<small>Entrer le mot de passe du lab</small>
							</div>

							<div
								class="flex"
								style="display: flex; justify-content: end"
							>
								<button
									@click.prevent="addFilter()"
									type="submit"
									class="btn btn-primary"
									style="margin-right: 5px"
								>
									Enregistrer
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { CreateFilter } from "../../gql/mutations/createFilter"
import { FindFilters } from "../../gql/queries/typefilters"

export default {
	data() {
		return {
			CreateFilter,
			createFilterInput: {},
			FindFiltersResponse: null,
			FindFilters
		}
	},

	methods:{
		async addFilter(){
			await this.$apollo.mutate({
				mutation: CreateFilter,
				variables: {
					createFilterInput: this.createFilterInput
				}
			})
			this.$swal({
				toast: true,
				position: 'top-end',
				showConfirmButton: false,
				timer: 1200,
				timerProgressBar: true,
				didOpen: (toast) => {
					toast.addEventListener('mouseenter', this.$swal.stopTimer)
					toast.addEventListener('mouseleave', this.$swal.resumeTimer)
				},
				icon: 'success',
				title: 'Ajouté avec succès'
			});
			
            this.$router.push({ name: "Filtres" });
            setTimeout(function(){
                window.location.reload(false);
            }, 1000);
		},
	},

	async mounted() {
		const filtres = await this.$apollo.query({
			query: FindFilters,
		})
		if(!filtres.loading){this.FindFiltersResponse = filtres.data.typeFilters}
		// console.log(this.FindFiltersResponse);
	},
}

</script>
<style scoped>

.select-style {
    padding: 7px 1.25rem;
    border: 1px solid #e9e8ee;
    width: 100%;
    height: 40px;
    border-radius: 3px;
    background: #fff;
    position: relative;
}
.select-style select {
    padding: 5px 8px;
    width: 130%;
    border: none;
    box-shadow: none;
    background-color: transparent;
    background-image: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.select-style:after {
    top: 50%;
    left: 85%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(0, 0, 0, 0);
    border-top-color: #000000;
    border-width: 5px;
    margin-top: -2px;
    z-index: 100;
}
.select-style select:focus {
    outline: none;
}


</style>