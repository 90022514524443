import gql from 'graphql-tag'

export const CreateAbonnement = gql`mutation createabonnement($createabonnementInput: CreateAbonnementInput!) {
    createabonnement(createabonnementInput: $createabonnementInput) {
      _id
      libelle
      price
      validity
    }
  }
`

export const Updateabonnement = gql`mutation updateabonnement($_id:ID!, $updateabonnementInput:UpdateAbonnementInput!){
  updateabonnement(_id:$_id, updateabonnementInput:$updateabonnementInput){
    _id
    description
    libelle
    price
    validity
  }
}
`

export const RemoveAbonnement = gql`mutation removeabonnement($_id: ID!) {
    removeabonnement(_id: $_id) {
      _id
      libelle
      description
      validity
    }
}`