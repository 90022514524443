import gql from "graphql-tag";

export const FindFilters = gql `query typeFilters {
    typeFilters {
      _id
      title
      description
      filters{_id value createdAt}
      createdAt
      isActive
    }
}`

export const FindFilter = gql `query typefilter($_id: ID!) {
    typefilter(_id: $_id) {
      _id
      title
      description
      filters{_id value createdAt labs{_id name}}
      createdAt
      isActive
    }
}`