import gql from "graphql-tag";

export const Abonnements = gql`query {
  abonnements {
    _id
    description
    libelle
    price
    validity
    subscriptions {
      _id
      start
      end
      status
      users {
        _id
        name
        lastname
        email
      }
    }
  }
}
`

export const Abonnement = gql`query abonnement($_id: ID!) {
  abonnement(_id: $_id) {
    _id
    description
    libelle
    price
    validity
    subscriptions {
      _id
      start
      end
      status
      users {
        _id
        name
        lastname
        email
      }
    }
  }
}`