import gql from "graphql-tag";

export const CreateAdmin = gql`mutation createAdmin($createAdminInput: CreateAdminInput!) {
    createAdmin(createAdminInput: $createAdminInput) {
      _id
      email
      fullname
      phone
      role
      status
      createdAt
    }
}`

export const RemoveAdmin = gql`mutation removeAdmin($_id: ID!) {
    removeAdmin(_id: $_id) {
      _id
      email
      fullname
      phone
      role
    }
  }
`