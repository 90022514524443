<template>
	<div class="container-fluid p-4">
		<div class="row">
			<!-- Page Header -->
			<div class="col-lg-12 col-md-12 col-12">
				<div
					class="
						border-bottom
						pb-4
						mb-4
						d-md-flex
						align-items-center
						justify-content-between
					"
				>
					<div class="mb-3 mb-md-0">
						<h1 class="mb-1 h2 fw-bold">Catégories de labs</h1>
					</div>
					<div>
						<a
							href="#"
							class="btn btn-primary"
							data-bs-toggle="modal"
							data-bs-target="#newCatgory"
							>Ajouter catégorie</a
						>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-12 col-md-12 col-12">
				<!-- Card -->
				<div class="card mb-4">
					<!-- Card header -->
					<div class="card-header border-bottom-0">
						<span class="mb-1 h4 fw-bold">Liste des catégories de labs</span>
						<!-- <form class="d-flex align-items-center">
							<span class="position-absolute ps-3 search-icon">
								<i class="fe fe-search"></i>
							</span>
							<input
								type="search"
								class="form-control ps-6"
								placeholder="Rechercher une catégorie de lab"
							/>
						</form> -->
					</div>
					<!-- Table -->
					<div class="table-responsive border-0 overflow-y-hidden">
						<table class="table mb-0 text-nowrap">
							<thead class="table-light">
								<tr>
									<th class="border-0 font-size-inherit">
										<div
											class="form-check"
											style="visibility: hidden"
										>
											<input
												type="checkbox"
												class="form-check-input"
												id="checkAll"
											/>
											<label
												class="form-check-label"
												for="checkAll"
											></label>
										</div>
									</th>
									<th class="border-0">CATEGORIES</th>
									<th class="border-0">Labs</th>
									<th class="border-0">
										<i class="fe fe-more-vertical"></i>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="categorie in CategoriesResponse" :key="categorie._id">
									<td class="align-middle">
										<div class="form-check">
											<input
												type="checkbox"
												class="form-check-input"
												id="categoryCheck3"
											/>
											<label
												class="form-check-label"
												for="categoryCheck3"
											></label>
										</div>
									</td>
									<td class="align-middle">
										<a class="text-inherit">
											<h5 class="mb-0 text-primary-hover">{{ categorie.name }}</h5>
										</a>
									</td>
									<td class="align-middle">{{ categorie.lab.length }}</td>
									<td class="align-middle border-top-0">
										<span class="dropdown dropstart">
											<a
												class="text-decoration-none text-muted"
												role="button"
												id="courseDropdown1"
												data-bs-toggle="dropdown"
												data-bs-offset="-20,20"
												aria-expanded="false"
											>
												<i class="fe fe-more-vertical"></i>
											</a>
											<span
												class="dropdown-menu"
												aria-labelledby="courseDropdown1"
											>
												<span class="dropdown-header">Actions</span>
												<a class="dropdown-item"
													><i
														class="fe fe-edit dropdown-item-icon"
													></i
													>Modifier</a
												>
												<a class="dropdown-item"
													data-bs-toggle="modal"
													:data-bs-target="'#supprimer' + categorie._id"
												>
												<i class="fe fe-trash dropdown-item-icon"></i> Supprimer</a>
											</span>
										</span>
									</td>

									<!-- Modal suppression -->
									<div
										class="modal fade"
										:id="'supprimer' + categorie._id"
										tabindex="-1"
										role="dialog"
										aria-labelledby="newLabLabel"
										aria-hidden="true"
									>
										<div class="modal-dialog">
											<div class="modal-content">
												<div class="modal-body text-center">
													<h3 class="modal-title" id="exampleModalCenterTitle">Êtes vous sûr de vouloir supprimer ?</h3>
													<h4 class="modal-title">Cette action est irréversible</h4>
												</div>
												<div class="modal-footer flex"
													style="display: flex; justify-content: space-between; "
												>
													<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
													<button type="button" class="btn btn-danger" @click="remove(categorie._id)" data-bs-dismiss="modal" onclick="window.location.reload(false)">
														Supprimer
													</button>
												</div>
											</div>
										</div>
									</div>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- Modal -->
	<div
		class="modal fade"
		id="newCatgory"
		tabindex="-1"
		role="dialog"
		aria-labelledby="newCatgoryLabel"
		aria-hidden="true"
	>
		<div class="modal-dialog modal-dialog-centered modal-lg">
			<div class="modal-content">
				<div class="modal-header">
					<h4 class="modal-title mb-0" id="newCatgoryLabel">
						Créer une catégorie de labs
					</h4>
					<button
						type="button"
						class="btn-close"
						data-bs-dismiss="modal"
						aria-label="Close"
					></button>
				</div>
				<div class="modal-body">
					<form>
						<div class="mb-3 mb-2">
							<label class="form-label" for="title"
								>Nom<span class="text-danger">*</span></label
							>
							<input
								type="text"
								class="form-control"
								placeholder="Nom de la catégorie"
								id="title"
								required
								v-model="categoryInput.name"
							/>
							<small>Nom de la catégorie de cours</small>
						</div>
						<!-- <div class="mb-3 mb-2">
							<label class="form-label">Type</label>
							<div class="input-group">
								<div class="input-group-prepend">
									<span class="input-group-text" id="type">
										Ex : ( Mobile, Web, etc )</span
									>
								</div>
								<input
									type="text"
									class="form-control"
									id="basic-url"
									aria-describedby="type"
									placeholder="Type de cours"
									required
								/>
							</div>
							<small>Type du cours</small>
						</div> -->
						<div
							class="flex"
							style="display: flex; justify-content: space-between"
						>
							<button
								type="button"
								class="btn btn-outline-white"
								data-bs-dismiss="modal"
							>
								Fermer
							</button>
							<button
								@click.prevent="addCategorie()"
								data-bs-dismiss="modal"
								type="submit"
								class="btn btn-primary"
								style="margin-right: 5px"
								onclick="window.location.reload(false)"
							>
								Enregistrer
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { Categories } from "../gql/queries/categories"
import { createCategorie } from "../gql/mutations/categories"
import { Removecategory } from "../gql/mutations/removecategorie"
export default {

	data() {
		return {
			CategoriesResponse: null,
			createCategorie,
			categoryInput: {},
			Removecategory
		};
	},

	methods: {
		addCategorie(){
			this.$apollo.mutate({
				mutation: createCategorie,
				variables: {
					categoryInput:{
						name:this.categoryInput.name
					}
				}
			})
			// this.$router.push({ name: "Categories" });
		},

		remove(id) {
			this.$apollo.mutate({
				mutation: Removecategory,

				variables: {
					id: id,
				}
			})
		}
	},
		


	async mounted(){
		const response = await this.$apollo.query({
			query:Categories
		})
		console.log(response)

		if(!response.loading){this.CategoriesResponse = response.data.categories}
	},
}


</script>