<template>
	<div class="container-fluid p-4">
		<div class="row">
			<!-- Page Header -->
			<div class="col-lg-12 col-md-12 col-12">
				<div
					class="
						border-bottom
						pb-4
						mb-4
						d-md-flex
						align-items-center
						justify-content-between
					"
				>
					<div class="mb-3 mb-md-0">
						<h1 class="mb-1 h2 fw-bold">
							Gestions des Mots de passes
							<!-- <span class="fs-5">(210 Courses)</span> -->
						</h1>
					</div>
					<div>
						<a
							class="btn btn-primary"
							@click="$router.push({ name: 'Addpassword' })"
						>
							Attribuer un mot de passe
						</a>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-12 col-md-12 col-12">
				<!-- Card -->
				<div class="card mb-4">
					<!-- Card Header -->
					<!-- <div class="card-header border-bottom-0">
						<form class="d-flex align-items-center">
							<span class="position-absolute ps-3 search-icon">
								<i class="fe fe-search"></i>
							</span>
							<input
								type="search"
								class="form-control ps-6"
								placeholder="Rechercher un cours"
							/>
						</form>
					</div> -->
					<!-- Table  -->
					<div class="table-responsive border-0 overflow-y-hidden">
						<table class="table mb-0 text-nowrap">
							<thead class="table-light">
								<tr>
									<th scope="col" class="border-0">LABS</th>
									<th scope="col" class="border-0">NIVEAU</th>
									<th scope="col" class="border-0">MOTS DE PASSE</th>
									<th scope="col" class="border-0">VOIR</th>
									<!-- <th scope="col" class="border-0">
										<i class="fe fe-more-vertical"></i>
									</th> -->
								</tr>
							</thead>
							<tbody>
								<tr v-for="codelab in CodelabsResponse" :key="codelab._id">
									<td class="border-top-0" style="width: 40%;">
										<a class="text-inherit">
											<div class="d-lg-flex align-items-center">
												<div>
													<img
														v-if="codelab.bannerImage === null"
														src="https://formations.jkp-dev.com/images/project-1.png"
														alt=""
														class="img-4by3-lg rounded"
													/>
													<img
														v-else
														:src="getImagebanner(codelab.bannerImage)"
														alt=""
														class="img-4by3-lg rounded"
													/>
												</div>
												<div class="ms-lg-3 mt-2 mt-lg-0 text-truncate" style="width: 250px">
													<h4 class="mb-1 text-primary-hover">
														{{ codelab.name }}
													</h4>
													<span class="text-inherit text-truncate"
														>{{ codelab.details?.description }}</span
													>
												</div>
											</div>
										</a>
									</td>
									<td class="align-middle border-top-0">
										<h5 v-if="codelab.details?.level === 'BEGINNER'">Débutant</h5>
										<h5 v-if="codelab.details?.level === 'INTERMEDIATE'">Intermmédiaire</h5>
										<h5 v-if="codelab.details?.level === 'ADVANCED'">Avancé</h5>
									</td>
									<td class="align-middle border-top-0" v-if="codelab.passwords.length === 0">
										<h5 style="color: #19cb98">Aucun mot de passe (Gratuit)</h5>
									</td>
									<td class="align-middle border-top-0" v-else>
										<h5>{{ codelab.passwords.length }}</h5>
									</td>
									<td class="align-middle" v-if="codelab.passwords.length === 0">
									</td>
									<td class="align-middle" v-else>
										<a
											@click.prevent="VoirPass(codelab._id)"
											class="btn btn-success btn-sm"
											data-bs-toggle="modal"
											data-bs-target="#voirPassword"
										>
											<i class="fe fe-eye"></i>
										</a>
									</td>
									<!-- <td class="align-middle border-top-0">
										<span class="dropdown dropstart">
											<a
												class="text-decoration-none text-muted"
											
												role="button"
												id="courseDropdown1"
												data-bs-toggle="dropdown"
												data-bs-offset="-20,20"
												aria-expanded="false"
											>
												<i class="fe fe-more-vertical"></i>
											</a>
											<span
												class="dropdown-menu"
												aria-labelledby="courseDropdown1"
											>
												<span class="dropdown-header">Actions</span>
												<a class="dropdown-item"
													><i
														class="fe fe-edit dropdown-item-icon"
													></i
													>Modifier</a
												>
												<a class="dropdown-item">
													<i class="fe fe-trash dropdown-item-icon"></i>
													Supprimer
												</a>
											</span>
										</span>
									</td> -->
								</tr>
								
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>

		<!-- Modal Voir Password -->
		<div
			class="modal fade"
			id="voirPassword"
			tabindex="-1"
			role="dialog"
			aria-labelledby="VoirLabel"
			aria-hidden="true"
		>
			<div class="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title mb-0" id="VoirLabel">
							Liste des Passwords du lab : <span class="fw-bold text-center" style="color:red"> {{ PassLabResponse.name }} </span>
						</h4>
						<button
							type="button"
							class="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
						></button>
					</div>
					<div class="modal-body">
						<div class="fs-4">


							<!-- ===== Modal Voir Mot de passes ======= -->
							<div class="table-responsive border-0 overflow-y-hidden">
								<table class="table mb-0 text-nowrap">
									<thead class="table-light">
										<tr>
											<th scope="col" class="border-0">MOTS DE PASSE</th>
											<th scope="col" class="border-0">Description</th>
											<th scope="col" class="border-0">Créé le</th>
											<th scope="col" class="border-0">Status</th>
											<th scope="col" class="border-0">Supprimer</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="password in PassLabResponse.passwords" :key="password._id">
											<td class="align-middle border-top-0">
												<h5>{{ password.pass }}</h5>
											</td>
											<td>{{ password.description }}</td>
											<td>{{ password.createdAt.substring(0, 10).split("-").reverse().join("/") }}</td>
											<td class="align-middle" v-if="password.isActive === true">
												<span class=" badge-dot bg-success me-1 d-inline-block align-middle"></span>
												Activé
											</td>
											<td class="align-middle" v-else>
												<span class=" badge-dot bg-danger me-1 d-inline-block align-middle"></span>
												Désactivé
											</td>
											<td class="align-middle text-center">
												<a
													class="btn btn-danger btn-xs"
													@click="remove(password._id)"
												>
													<i class="fe fe-trash-2" style="font-size: 1rem; opacity: .7; width: 1.5rem;"></i>
												</a>
											</td>
										</tr>
										
									</tbody>
								</table>
							</div>
							<!-- ============ -->

							<div
								class="flex mt-5"
								style="display: flex; justify-content: end"
							>
								<button
									type="button"
									class="btn btn-outline-white"
									data-bs-dismiss="modal"
								>
									Fermer
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- Modal voir Password -->
	</div>
</template>

<script>
import { Codelabs } from "../../gql/queries/codelabs"
import { FindAllcodelabs } from "../../gql/queries/codelabs";
import { Lab } from "../../gql/queries/codelabs"
import { RemovePassword } from "../../gql/mutations/passwords"

export default {
	data() {
		return {
			Codelabs,
			CodelabsResponse: null,
			baseUrl: "https://api.labs.jkp-dev.com/",
			Lab,
			PassLabResponse: {},
			RemovePassword
		}
	},

	methods: {
		getImagebanner(imageLink) {
			return imageLink
		},

		async VoirPass(LabID){
			const PassLab = await this.$apollo.query({
				query: Lab,
				variables:{
					id : LabID
				},
				})
				console.log(LabID)
			if (!PassLab.loading){this.PassLabResponse = PassLab.data.codelab}
			console.log(this.PassLabResponse)
		},

		async remove(id) {
			const response = await this.$swal({
				title: "Êtes vous sûre ?",
				text: "Cette action est irréversible",
				type: "warning",
				showCancelButton: true,
				cancelButtonText: "Non !",
				confirmButtonText: "Oui je confirme !",
				showCloseButton: true,
				showLoaderOnConfirm: true,
			});

			if (response.isConfirmed) {
				this.$apollo.mutate({
					mutation: RemovePassword,

					variables: {
						id: id,
					}
				})
				await this.$swal({
					toast: true,
					position: "top-end",
					showConfirmButton: false,
					timer: 1000,
					timerProgressBar: true,
					icon: "success",
					title: "Suppression réussie !",
				});
				window.location.reload(false)
			}

		},

		

	},

	// computed: {
	// 	computedLabs(){
	// 		return this.CodelabsResponse.map((codelabs)=> (
	// 			{...this.codelabs,details: JSON.parse(codelabs.details)}
	// 		))
	// 	}
	// },

	async mounted() {
		const response = await this.$apollo.query({
			query:FindAllcodelabs
		})

		if(!response.loading){this.CodelabsResponse = response.data.findAllcodelabs.map((findAllcodelabs)=> (
			{...findAllcodelabs,details: JSON.parse(findAllcodelabs.details)}
		))}

	},
}

</script>