import gql from "graphql-tag";

export const Subscriptions = gql`query {
    subscriptions {
      _id
      abonnement {
        _id
        libelle
        description
        validity
        price
        description
      }
      isActive
      paymentToken
      start
      end
      status
      users {
        _id
        email
        name
        lastname
        birth
        createdAt
        lastSubscriptionEnd
        lastConnexion
        mailWasConfirmed
        phone_number
        role
        status
        subscriptionStart
      }
    }
  }  
`

export const Subscription = gql`query subscription($id: ID!) {
  subscription(id: $id) {
    _id
    abonnement {
      _id
      libelle
      description
      validity
      price
      description
    }
    isActive
    paymentToken
    start
    end
    status
    users {
      _id
      email
      name
      lastname
      birth
      createdAt
      lastSubscriptionEnd
      lastConnexion
      mailWasConfirmed
      phone_number
      role
      status
      subscriptionStart
    }
  }
}`