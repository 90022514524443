<template>
	<div class="container-fluid p-4">
		<div class="row">
			<!-- Page Header -->
			<div class="col-lg-12 col-md-12 col-12">
				<div
					class="
						border-bottom
						pb-4
						mb-4
						d-md-flex
						align-items-center
						justify-content-between
					"
				>
					<div class="mb-3 mb-md-0">
						<h1 class="mb-1 h2 fw-bold">
							Ajouter un nouvel étudiant
							<!-- <span class="fs-5">(210 Courses)</span> -->
						</h1>
					</div>
					<div>
						<a
							class="btn btn-secondary"
							@click="$router.push({ name: 'Students' })"
						>
							Retour
						</a>
					</div>
				</div>
			</div>
		</div>
		<div class="row justify-content-md-center">
			<div class="col-lg-6 col-md-6 col-12">
				<!-- Card -->
				<div class="card mb-4">
					<!-- Card Header -->
					<div class="modal-body">
						<form>
                            <div class="mb-3">
								<label class="form-label" for="fname">
									Nom
								</label>
								<input
									v-model="createUserInput.name"
									type="text"
									class="form-control"
									placeholder="Nom et prénoms"
									id="fname"
									required
								/>
							</div>

                            <div class="mb-3">
								<label class="form-label" for="fname">
									Prénoms
								</label>
								<input
									v-model="createUserInput.lastname"
									type="text"
									class="form-control"
									placeholder="Prénoms"
									id="fname"
									required
								/>
							</div>

                            <div class="mb-3">
								<label class="form-label" for="fname">
									Date de naissance
								</label>
								<input
									v-model="createUserInput.birth"
									type="date"
									class="form-control"
									placeholder="Date de naissance"
									id="fname"
									required
								/>
							</div>
                            <div class="mb-3">
								<label class="form-label" for="email">
									Email
								</label>
								<input
									v-model="createUserInput.email"
									type="text"
									class="form-control"
									placeholder="Email"
									id="email"
									required
								/>
							</div>
                            <div class="mb-3">
								<label class="form-label" for="password">
									Mot de passe <span class="text-danger">*</span>
								</label>
								<input
									v-model="createUserInput.password"
									type="text"
									class="form-control"
									placeholder="Mot de passe"
									id="password"
									required
								/>
							</div>
							
                            <div class="mb-6">
								<label class="form-label" for="phone">
									Téléphone <span class="text-danger">*</span>
								</label>
								<input
									v-model="createUserInput.phone_number"
									type="text"
									class="form-control"
									placeholder="phone"
									id="phone"
									required
								/>
							</div>

							<div
								class="flex"
								style="display: flex; justify-content: end"
							>
								<button
									@click.prevent="addStudent()"
									type="submit"
									class="btn btn-primary"
									style="margin-right: 5px"
								>
									Enregistrer
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import {CreateStudent} from "../../gql/mutations/createStudent.js"

export default {
	data() {
		return {
		
			createUserInput: {}
		}
	},

	methods:{
		async addStudent(){
            this.createUserInput.role="STUDENT";
			await this.$apollo.mutate({
				mutation: CreateStudent,
				variables: {
					createUserInput: this.createUserInput
				}
			})
			this.$swal({
				toast: true,
				position: 'top-end',
				showConfirmButton: false,
				timer: 1200,
				timerProgressBar: true,
				didOpen: (toast) => {
					toast.addEventListener('mouseenter', this.$swal.stopTimer)
					toast.addEventListener('mouseleave', this.$swal.resumeTimer)
				},
				icon: 'success',
				title: 'Ajouté avec succès'
			});
			
            this.$router.push({ name: "Students" });
			
            setTimeout(function(){
                window.location.reload(false);
            }, 1000);
		},
	},
}

</script>
<style scoped>

.select-style {
    padding: 7px 1.25rem;
    border: 1px solid #e9e8ee;
    width: 100%;
    height: 40px;
    border-radius: 3px;
    background: #fff;
    position: relative;
}
.select-style select {
    padding: 5px 8px;
    width: 130%;
    border: none;
    box-shadow: none;
    background-color: transparent;
    background-image: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.select-style:after {
    top: 50%;
    left: 85%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(0, 0, 0, 0);
    border-top-color: #000000;
    border-width: 5px;
    margin-top: -2px;
    z-index: 100;
}
.select-style select:focus {
    outline: none;
}


</style>