<template>
	<div>
		<!-- navbar vertical -->
		<!-- Sidebar -->
		<nav class="navbar-vertical navbar">
			<div class="nav-scroller">
				<!-- Brand logo -->
				<a
					class="navbar-brand"
					@click="$router.push({ name: 'Dashboard' })"
				>
					<img src="assets/images/brand/logo/logo.png" alt="" />
				</a>
				<!-- Navbar nav -->
				<ul
					class="navbar-nav flex-column"
					id="sideNavbar"
					style="font-size: 15px"
				>
					<li class="nav-item">
						<router-link
							class="nav-link"
							to="Dashboard"
							:class="[$route.name === 'Dashboard' ? 'active' : '']"
						>
							<i class="nav-icon fe fe-home me-2"></i> Dashboard
						</router-link>
					</li>

					<li class="nav-item">
						<router-link
							class="nav-link"
							to="Labs"
							:class="[$route.name === 'Labs' ? 'active' : '']"
						>
							<i class="nav-icon fe fe-book me-2"></i> Labs
						</router-link>
					</li>

					<li class="nav-item">
						<router-link
							class="nav-link"
							to="Passwords"
							:class="[$route.name === 'Passwords' ? 'active' : '']"
						>
							<i class="nav-icon fe fe-lock me-2"></i> Mots de passes
						</router-link>
					</li>

					<!-- <li class="nav-item">
						<router-link
							class="nav-link"
							to="Categories"
							:class="[$route.name === 'Categories' ? 'active' : '']"
						>
							<i class="nav-icon fe fe-book-open me-2"></i> Catégories
						</router-link>
					</li> -->

					<!-- <li class="nav-item">
                        <router-link class="nav-link" to="Niveaux" :class="[$route.name === 'Niveaux' ? 'active' : '']">
                            <i class="nav-icon fe fe-git-pull-request me-2"></i> Niveaux
                        </router-link>
                    </li> -->
					
					<li class="nav-item">
                        <router-link class="nav-link" to="Types" :class="[$route.name === 'Types' ? 'active' : '']">
                            <i class="nav-icon fe fe-book-open me-2"></i> Catégories de filtres
                        </router-link>
                    </li>
					
					<li class="nav-item">
                        <router-link class="nav-link" to="Filtres" :class="[$route.name === 'Filtres' ? 'active' : '']">
                            <i class="nav-icon fe fe-git-pull-request me-2"></i> Filtres
                        </router-link>
                    </li>

					<li class="nav-item">
						<router-link
							class="nav-link"
							to="Abonnements"
							:class="[$route.name === 'Abonnements' ? 'active' : '']"
						>
							<i class="nav-icon fe fe-user me-2"></i> Abonnements
						</router-link>
					</li>

					<li class="nav-item">
						<router-link
							class="nav-link"
							to="Subscriptions"
							:class="[$route.name === 'Subscriptions' ? 'active' : '']"
						>
							<i class="nav-icon fe fe-calendar me-2"></i> Souscriptions
						</router-link>
					</li>

					<li class="nav-item">
						<router-link
							class="nav-link"
							to="Students"
							:class="[$route.name === 'Students' ? 'active' : '']"
						>
							<i class="nav-icon fe fe-user me-2"></i> Etudiants
						</router-link>
					</li>
					
					<li class="nav-item">
						<router-link
							class="nav-link"
							to="Users"
							:class="[$route.name === 'Users' ? 'active' : '']"
						>
							<i class="nav-icon fe fe-user me-2"></i> Utilisateurs
						</router-link>
					</li>

				</ul>
			</div>
		</nav>
	</div>
</template>
<script>
</script>
<style scoped>
mood {
	background-color: rgb(241, 38, 2);
}

/* li:hover {
    background-color: blanchedalmond;
} */
</style>