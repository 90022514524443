<template>
  <div class="container-fluid p-2">
    <div class="row">
      <!-- Page Header -->
      <div class="col-lg-12 col-md-12 col-12">
        <div
          class="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between"
        >
          <div class="mb-3 mb-md-0">
            <h1 class="mb-1 h2 fw-bold">
              Modifier le lab
              <!-- <span class="fs-5">(210 Courses)</span> -->
            </h1>
          </div>
          <div>
            <a
              class="btn btn-secondary"
              @click="$router.push({ name: 'Labs' })"
            >
              Retour
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-sm-center">
      <!-- {{labDetailsInput}} -->
      <div class="col-lg-8 col-md-8 col-12">
        <!-- Card -->
        <div class="card mb-4">
          <!-- Card Header -->
          <div class="modal-body">
            <form>
              <div class="mb-3">
                <label class="form-label" for="title">
                  Nom du lab <span class="text-danger">*</span>
                </label>
                <input
                  v-model="form.input.name"
                  type="text"
                  class="form-control"
                  placeholder="Nom du lab"
                  required
                />
              </div>

              <div class="mb-3">
                <label class="form-label" for="title">
                  Description <span class="text-danger">*</span>
                </label>
                <input
                  v-model="form.input.details.description"
                  type="text"
                  class="form-control"
                  placeholder="Description"
                  required
                />
              </div>

              <div class="mb-3">
                <label class="form-label" for="title">
                  Niveau <span class="text-danger">*</span>
                </label>
                <select
                  v-model="form.input.details.level"
                  class="select-style"
                  data-width="100%"
                  required
                >
                  <option value="BEGINNER">Débutant</option>
                  <option value="INTERMEDIATE">Intermmédiaire</option>
                  <option value="ADVANCED">Avancé</option>
                </select>
                <small> Niveau du lab </small>
              </div>

              <div class="mb-3">
                <label class="form-label" for="title">
                  Temps <span class="text-danger">*</span>
                </label>
                <input
                  v-model="form.input.details.time"
                  type="text"
                  class="form-control"
                  placeholder="Durée (Ex : 1h30)"
                  required
                />
              </div>

              <div class="mb-3">
                <label class="form-label" for="title"> Path </label>
                <input
                  disabled=""
                  v-model="form.input.path"
                  type="text"
                  class="form-control"
                  placeholder="Path"
                  required
                />
              </div>

              <div class="mb-5" v-if="this.PassTableau.length === 0"></div>

              <div class="mb-3" v-else>
                <label class="form-label" for="title">
                  Mot de passe <span class="text-danger">*</span>
                </label>
                <input
                  v-model="form.input.password"
                  type="text"
                  class="form-control"
                  placeholder="Mot de passe"
                  required
                />
                <small> Mot de passe du lab </small>
              </div>

              <div class="mb-4">
                <label class="form-label" for="title">
                  Status <span class="text-danger">*</span>
                </label>
                <select
                  v-model="form.input.status"
                  class="select-style"
                  data-width="100%"
                  required
                >
                  <option :value= true>Activé</option>
                  <option :value= false>Désactivé</option>
                </select>
                <label for="customSwitch1" style="margin-right: 5px">
                  Status du lab
                </label>
                <input
                  v-model="form.input.status"
                  type="checkbox"
                  id="customSwitch1"
                />
              </div>

              <!-- <div class="form-group mb-4">
								<label class="form-label" for="textareaInput"
									>Page de details</label<iframe height="300" style="width: 100%;" scrolling="no" title="Magic Card" src="https://codepen.io/gayane-gasparyan/embed/jOmaBQK?default-tab=html%2Cresult&editable=true&theme-id=dark" frameborder="no" loading="lazy" allowtransparency="true" allowfullscreen="true">
  See the Pen <a href="https://codepen.io/gayane-gasparyan/pen/jOmaBQK">
  Magic Card</a> by Gayane (<a href="https://codepen.io/gayane-gasparyan">@gayane-gasparyan</a>)
  on <a href="https://codepen.io">CodePen</a>.
</iframe>
								>

								<textarea
									id="textareaInput"
									class="form-control"
									placeholder="Details"
									v-model="form.input.contenue_details"
									rows="4"
								></textarea>
								<small>details de la page de labs </small>
							</div> -->

             
              <div class="form-group mb-4">
                <label class="form-label" for="textareaInput"
                  >Page de details</label
                >

                <Vue3JsonEditor
                  :mode="'tree'"
                  v-model="conteneExemple"
                  :expandedOnStart="true"
                  @json-change="onJsonChange"
                />
                <small>details de la page de labs </small>
              </div>

              <div class="mb-4">
                <button @click="setSizeMoins" type="button" class="btn btn-secondary btn-xs mr-1">
                  <i class="fe fe-minus"></i>
                </button>
                <button @click="setSizePlus" type="button" class="btn btn-secondary btn-xs ml-1">
                  <i class="fe fe-plus"></i>
                </button>
                <AceEditor
                  v-model:codeContent="value" 
                  v-model:editor="editor"
                  :options="options"
                  :theme="theme"
                  :lang="lang"
                  width="100%" 
                  height="600px" 
                />
              </div>

              <div class="flex" style="display: flex; justify-content: end">
                <button v-if="Isloading" class="btn btn-primary" disabled>
                  Chargement <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </button>
                <button v-else
                  @click.prevent="updateCodelab()"
                  type="submit"
                  class="btn btn-primary"
                  style="margin-right: 5px"
                >
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Codelab } from '../../gql/queries/codelabs';
  import { Updatecodelab } from '../../gql/mutations/codelabs';
  // import conteneExemple from '../../contenu_details.json';
  import { defineComponent } from 'vue';
  import { Vue3JsonEditor } from 'vue3-json-editor';
  import AceEditor from 'ace-editor-vue3';
  import "brace/mode/html"
  import "brace/theme/monokai";
  import { mapStores } from 'pinia'
  import { useCodelabStore } from '../../stores/codelabs'


  export default defineComponent({
    components: {
      Vue3JsonEditor,
      AceEditor,
    },
    setup() {},
    data() {
      return {
        Codelab,
        codelabID: this.$route.params.id,
        PassTableau: [],
        conteneExemple: {},
        updatedContenuDetail: null,
        dataReady: false, Isloading: null,
        form: {
          input: {
            detailLab: '',
            name: null,
            details: {
              description: '',
              level: '',
              time: '',
            },
            password: null,
            path: null,
            contenue_details: null,
            status: null,
          },
        },
        Updatecodelab,

        editor: null,
        value: ``,
        options: {"showPrintMargin": false},
        lang: "html",
        theme: "monokai", 
        fontSize: 14,
      };
    },

    computed:{
      ...mapStores(useCodelabStore),
    },

    methods: {
      wait (){
        this.Isloading = true
      },
      async onJsonChange(value) {
        this.updatedContenuDetail = value;
      },
      async updateCodelab() {
        this.wait()

        if(!this.updatedContenuDetail){
          this.updatedContenuDetail = this.conteneExemple;
        }
     
        await this.$apollo.mutate({
          mutation: Updatecodelab,
          variables: {
            id: this.codelabID,
            updateLabInput: {
              name: this.form.input.name,
              details: JSON.stringify({
                description: this.form.input.details.description,
                time: this.form.input.details.time,
                level: this.form.input.details.level,
              }),
              contenue_details: JSON.stringify(this.updatedContenuDetail),
              indexContent: this.value,
              password: this.form.input.password,
              path: this.form.input.path,
              status: this.form.input.status,
            },
          },
        }).then(this.dataReady = true);
        this.$swal({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 1200,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', this.$swal.stopTimer);
            toast.addEventListener('mouseleave', this.$swal.resumeTimer);
          },
          icon: 'success',
          title: 'Mis à jour avec succès',
        });

        this.$router.push({ name: 'Labs' });
        setTimeout(function () {
          window.location.reload(false);
        }, 1000);
        // console.log(response2);
      },

      setSizePlus() {
        this.fontSize = this.fontSize + 2
        this.editor.setFontSize(this.fontSize);
      },

      setSizeMoins() {
        this.fontSize = this.fontSize - 2
        this.editor.setFontSize(this.fontSize);
      },
    },

    async mounted() {

      this.editor.setFontSize(this.fontSize)

      const response = await this.$apollo.query({
        query: Codelab,
        variables: {
          id: this.codelabID,
        },
      });

		if (response && !response.loading) {
			this.LabResponse = response.data.codelab;
      this.conteneExemple = JSON.parse(JSON.parse(JSON.stringify(this.LabResponse.contenue_details)))

      this.value = this.LabResponse.indexContent
      this.PassTableau = this.LabResponse.passwords;

      this.form.input.name = this.LabResponse.name;
      this.form.input.path = this.LabResponse.path;
      const obj = JSON.parse(this.LabResponse.details);
      // console.log('Mood'+obj.time);
      this.form.input.details.description = obj.description;
      this.form.input.details.level = obj.level;
      this.form.input.details.time = obj.time;

      this.form.input.password = this.LabResponse?.passwords[0]?.pass;
      this.form.input.status = this.LabResponse.status;
		
		}

      
    },
  });
</script>

<style scoped>
  .select-style {
    padding: 7px 1.25rem;
    border: 1px solid #e9e8ee;
    width: 100%;
    height: 40px;
    border-radius: 3px;
    background: #fff;
    position: relative;
  }
  .select-style select {
    padding: 5px 8px;
    width: 130%;
    border: none;
    box-shadow: none;
    background-color: transparent;
    background-image: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .select-style:after {
    top: 50%;
    left: 85%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(0, 0, 0, 0);
    border-top-color: #000000;
    border-width: 5px;
    margin-top: -2px;
    z-index: 100;
  }
  .select-style select:focus {
    outline: none;
  }
</style>
